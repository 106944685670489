import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Image, View } from 'react-native'
import config from '../config'

import logoWal from '../assets/images/logo-wal.png'
import logoFullWal from '../assets/images/logo-full-wal.png'
import creditsWal from '../assets/images/credits-wal.png'
import tut1 from '../assets/images/tut1.png'
import tut2 from '../assets/images/tut2.png'
import tut3 from '../assets/images/tut3.png'
import loading from '../assets/images/loading.jpg'

const sources = {
  logo: logoWal,
  logoFull: logoFullWal,
  credits: creditsWal,
  tut1, tut2, tut3,
}

// Component code
//////////////////////////////////////////////////////////////////////////

export default function ImageComponent (props) {
  const [sizes, setSizes] = useState([props.width, props.height])

  if (!props.source) {
    return (
      <View
        style={[styles.image, { width: props.width, height: props.height }, { backgroundColor: config.colors.primary }, props.style]}
      />
    )
  }

  const source = useMemo(() => {
    let source, sourceImage, sourceType
    if(props.source.startsWith('http')) {
      source = props.source
      sourceImage = { uri: source }
      sourceType = 'external'
    } else {
      source = sources[props.source]
      sourceImage = sources[props.source]
      sourceType = 'internal'
    }

    return { source, sourceImage, sourceType }
  }, [props.source])

  

  useEffect(() => {
    if (config.consts.isWeb || source.sourceType === 'external') {
      Image.getSize(source.source, setCalculatedSizes, (error) => { console.log(error) })
    } else {
      const { width, height } = Image.resolveAssetSource(source.source)
      setCalculatedSizes(width, height)
    }
  }, [])

  const setCalculatedSizes = (width, height) => {
    const finalWidth = (props.width ? props.width : (props.height * width / height))
    const finalHeight = (props.height ? props.height : (props.width * height / width))
    setSizes([finalWidth, finalHeight])
  }

  return (
    <Image
      style={[styles.image, { width: sizes ? sizes[0] : props.width, height: sizes ? sizes[1] : props.height }, props.style]}
      source={source.sourceImage}
      defaultSource={loading}
      resizeMode={props.cover ? 'cover' : 'contain'}
    />
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  image: {}
})

// Component props
//////////////////////////////////////////////////////////////////////////

ImageComponent.propTypes = {
  source: PropTypes.any,
  cover: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any
}

ImageComponent.defaultProps = {
  width: 0,
  height: 0
}