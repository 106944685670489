import env from '../env.json'

const defaultColors = {
  black: '#444444',
  blackLight: '#888888',
  white: '#ffffff',
  primary: '#00b7ba',
  secondary: '#EDEDED',
  gray: '#C2BFBF',
  red: '#F31910',
  background: '#ffffff',
  sand: '#e3dcd8'
}

const envColors = env.colors || {}

export default Object.assign({}, defaultColors, envColors)