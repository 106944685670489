import React from 'react'
import { View, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import TitleComponent from './TitleComponent'
import ImageComponent from './ImageComponent'
import ButtonComponent from './ButtonComponent'
import config from '../config'

export default function HeroCharacterComponent(props) {
  return(
    <View style={styles.container}>
      <View style={styles.imageBall}>
        <ImageComponent source={props.image} cover width={'100%'} height={'100%'} />
      </View>
      <View style={styles.title}>
        <TitleComponent label={props.name} color={config.colors.black} labelSmall />
      </View>
      {props.ctaText && props.ctaOnPress && (
        <View style={styles.iconContainer}>
          <ButtonComponent  label={props.ctaText} onPress={props.ctaOnPress} arrow />
        </View>
      )}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////
const styles = StyleSheet.create({
  container: {
    alignItems: 'center'
  },
  imageBall : {
    height: 180,
    width: 180,
    borderWidth: 4,
    borderColor: config.colors.white,
    borderRadius: 180,
    overflow: 'hidden',
    ...config.style.shadow
  },
  title : {
    marginTop: 10,
    marginBottom: 10
  }
})


// Component props
//////////////////////////////////////////////////////////////////////////

HeroCharacterComponent.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaOnPress: PropTypes.func,
}
