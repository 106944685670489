import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import TextComponent from './TextComponent'
import { ArrowLeft, ArrowRight } from './IconComponent'
import { colorShade } from '../libs/helpers'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function SliderDotsComponent (props) {
  const basicStyle = props.number ? styles.dotNumbers : styles.dotNoNumbers
  const themeNumbersStyle = props.theme == 'dark' ? styles.dotNumbersDark : styles.dotNumbersLight
  const themeNoNumbersStyle = props.theme == 'dark' ? styles.dotNoNumbersDark : styles.dotNoNumbersLight
  const themeNumbersActiveStyle = props.theme == 'dark' ? styles.dotNumbersActiveDark : styles.dotNumbersActiveLight
  const themeNoNumbersActiveStyle = props.theme == 'dark' ? styles.dotNoNumbersActiveDark : styles.dotNoNumbersActiveLight
  const themeStyle = props.number ? themeNumbersStyle : themeNoNumbersStyle

  const dots = []
  if (props.total <= 6 || !props.number) {
    for (let i = 1; i <= props.total; i++) { dots.push(i) }

    return (
      <View style={[styles.container, props.style]}>
        {dots.map((item, index) => {
          const activeStyle = props.current == index ? (props.number ? styles.dotNumbersActive : styles.dotNoNumbersActive) : null
          const activeThemeStyle = props.current == index ? (props.number ? themeNumbersActiveStyle : themeNoNumbersActiveStyle) : null
  
          return (
            <TouchableOpacity key={index} style={[styles.dot, basicStyle, themeStyle, activeStyle, activeThemeStyle]} onPress={() => props.onPress(index)}>
              {props.number && <TextComponent label={item} color={config.colors.white} labelBold />}
            </TouchableOpacity>
          )
        })}
      </View>
    )
  } else {
    const startCounts = props.current < props.total - 3 ? props.current + 1 : props.total - 3
    const maxCounts = startCounts + 3
    for (let i = startCounts; i <= maxCounts; i++) { if (i <= props.total && i > 0) dots.push(i) }

    return (
      <View style={[styles.container, props.style]}>
        <TouchableOpacity style={[styles.dot, basicStyle, themeStyle]} onPress={props.current > 0 ? () => props.onPress(props.current - 1) : null}>
          {props.number && <ArrowLeft color={props.theme == 'dark' ? config.colors.black : config.colors.white} width={20} height={20} />}
        </TouchableOpacity>

        {dots.map((item) => {
          const index = item - 1
          const activeStyle = props.current == index ? (props.number ? styles.dotNumbersActive : styles.dotNoNumbersActive) : null
          const activeThemeStyle = props.current == index ? (props.number ? themeNumbersActiveStyle : themeNoNumbersActiveStyle) : null
          
          return (
            <TouchableOpacity key={index} style={[styles.dot, basicStyle, themeStyle, activeStyle, activeThemeStyle]} onPress={() => props.onPress(item - 1)}>
              {props.number && <TextComponent label={item} color={config.colors.white} labelBold />}
            </TouchableOpacity>
          )
        })}

        <TouchableOpacity style={[styles.dot, basicStyle, themeStyle]} onPress={props.current < props.total - 1 ? () => props.onPress(props.current + 1) : null}>
          {props.number && <ArrowRight color={props.theme == 'dark' ? config.colors.black : config.colors.white} width={20} height={20} />}
        </TouchableOpacity>

      </View>
    )
  }
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  dot: {},
  dotNumbers: {
    width: config.consts.isScreenMobile ? 40 : 60,
    height: config.consts.isScreenMobile ? 40 : 60,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    marginHorizontal: config.consts.isScreenMobile ? 5 : 15
  },
  dotNumbersDark: {
    borderColor: config.colors.sand,
    backgroundColor: config.colors.sand
  },
  dotNumbersLight: {
    borderColor: colorShade(config.colors.white, -150),
  },
  dotNumbersActive: {},
  dotNumbersActiveDark: {
    borderColor: config.colors.primary,
    backgroundColor: config.colors.primary
  },
  dotNumbersActiveLight: {
    borderColor: config.colors.white,
  },
  dotNoNumbers: {
    width: 20,
    height: 5,
    marginHorizontal: 5
  },
  dotNoNumbersDark: {
    backgroundColor: colorShade(config.colors.black, 150)
  },
  dotNoNumbersLight: {
    backgroundColor: colorShade(config.colors.white, 150)
  },
  dotNoNumbersActive: {},
  dotNoNumbersActiveDark: {
    backgroundColor: config.colors.black
  },
  dotNoNumbersActiveLight: {
    backgroundColor: config.colors.white
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

SliderDotsComponent.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  number: PropTypes.bool,
}

SliderDotsComponent.defaultProps = {
  number: false,
  onPress: () => {}
}