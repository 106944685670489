import React, { useMemo } from 'react'
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'
import { getVersion } from 'react-native-device-info'
import TextComponent from './TextComponent'
import ImageComponent from './ImageComponent'
import PaddedComponent from './PaddedComponent'
import NavComponent from './NavComponent'
import { getItem, setItem, removeItem } from '../libs/storage'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function DrawerComponent(props) {
  const version = useMemo(() => {
    return config.consts.isWeb ? 'WEB' : getVersion()
  }, [])

  const onToggleDebug = async () => {
    const debugMode = await getItem('debug')
    if (debugMode) {
      await removeItem('debug')
      alert("Modalità debug DISATTIVATA. Riavvia l'app per applicare le modifiche.")
    } else {
      await setItem('debug', { value: true })
      alert("Modalità debug ATTIVATA. Riavvia l'app per applicare le modifiche.")
    }
  }

  return (
    <View style={styles.container}>
      <PaddedComponent style={styles.header} horizontal vertical>
        <ImageComponent source='logoFull' width={125} />
      </PaddedComponent>
      <ScrollView>
        <PaddedComponent style={styles.body} horizontal vertical>
          <NavComponent itemStyle={styles.item} itemLabelStyle={styles.itemLabel} />
        </PaddedComponent>
      </ScrollView>
      <PaddedComponent style={styles.footer} horizontal vertical>
        <TextComponent label="© Walk & Learn" styleText={styles.footerLabel} labelCenter />
        <TextComponent label="Zeranta Edutainment Srl" styleText={styles.footerLabel} labelCenter />
        <TouchableOpacity onLongPress={onToggleDebug}>
          <TextComponent label={`Version ${version}`} styleText={styles.footerLabel} labelCenter />
        </TouchableOpacity>
      </PaddedComponent>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: config.colors.secondary
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 25
  },
  body: {

  },
  item: {
    paddingVertical: 15,
    borderBottomColor: config.colors.gray,
    borderBottomWidth: 1
  },
  itemLabel: {
    fontSize: 20,
    lineHeight: 20
  },
  footerLabel: {
    color: config.colors.gray
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

DrawerComponent.propTypes = {}

DrawerComponent.defaultProps = {}