import React, { useLayoutEffect, useState } from 'react'
import { debounce } from '../libs/helpers'
import config from '../config'

/**
 * @function useContainerScrollPosition
 * Hook per controllare la posizione di scroll dell'utente all'interno di un Container
 * NOTE: Da usare insieme alla componente Container.js nel caso di props scrollable
 */
export default function useContainerScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0)

  const scrollPositionUpdater = debounce((event) => {
    setScrollPosition(event?.nativeEvent?.contentOffset?.y || 0)
  }, 500)

  return [scrollPosition, scrollPositionUpdater]
}
