import React, { useState, useEffect } from 'react'
import { setItem, getItem } from '../libs/storage'
import config from '../config'

export const TutorialContext = React.createContext()

export function TutorialProvider (props) {
  const [tutorialVersion, setTutorialVersion] = useState(false)
  const [tutorialActive, setTutorialActive] = useState(true)
  const [tutorialLoaded, setTutorialLoaded] = useState(false)

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getTutorialData = async () => {
      const tutorialData = await getItem('tutorialData')

      if (tutorialData) {
        setTutorialVersion(tutorialData.tutorialVersion)
        setTutorialActive(tutorialData.tutorialVersion && tutorialData.tutorialVersion < config.consts.tutorialVersion)
      }

      setTutorialLoaded(true)
    }

    getTutorialData()
  }, [])

  //////////////////////////////////////////////////////////////////////////

  const manageTutorialViewed = async () => {
    setTutorialVersion(config.consts.tutorialVersion)
    setTutorialActive(false)
    await setItem('tutorialData', { tutorialVersion: config.consts.tutorialVersion })
  }

  //////////////////////////////////////////////////////////////////////////

  return (
    <TutorialContext.Provider value={{ tutorialActive, tutorialVersion, tutorialLoaded, manageTutorialViewed }} >
      {props.children}
    </TutorialContext.Provider>
  )
}
