import React, { useState } from 'react'

export const UiContext = React.createContext()

export function UiProvider (props) {
  const [showHeader, setShowHeader] = useState(true)

  return (
    <UiContext.Provider value={{ showHeader, setShowHeader }} >
      {props.children}
    </UiContext.Provider>
  )
}
