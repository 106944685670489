import React, { useState, useEffect, createRef } from 'react'
import Flickity from 'react-flickity-component'
import SliderDotsContainerComponent from './SliderDotsContainerComponent'
import SliderDotsComponent from './SliderDotsComponent'
import ButtonComponent from './ButtonComponent'
import * as SliderComponentProps from './SliderComponentProps'
import { ArrowRight } from './IconComponent'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function SliderComponent (props) {
  const [flickity, setFlickity] = useState(null)
  const [current, setCurrent] = useState(props.currentItem || 0)

  useEffect(() => { // FIX BRUTTISSIMO A BUG SLIDER WEB QUANDO E ATTIVA LA MODALITA wrapAround E SI FA BACK ALLA PAGINA PRECEDENTE
    if (flickity && (props.autoplay || props.infinity)) {
      flickity.resize()
    }
  }, [props, flickity])

  useEffect(() => {
    if (flickity) {
      flickity.on('change', (index) => setCurrent(index))
    }
  }, [flickity])

  // Gestione input aggiornamento da esterno
  useEffect(() => {
    if (props.currentItem != undefined && props.currentItem != null && props.currentItem != current) {
      setCurrent(props.currentItem)
    }
  }, [props.currentItem])

  // Gestione output aggiornamento a esterno
  useEffect(() => {
    props.onChangeItem(current)
    if (flickity) flickity.select(current)
  }, [current])

  /////////////////////////////////////////////////

  const onChangeDot = (index) => {
    setCurrent(index)
  }

  /////////////////////////////////////////////////

  const renderItem = (item, index) => {
    return (
      <div key={item && item.id ? item.id : index} style={{ width: `${100 / props.itemsToShow}%` }}>
        {props.renderItem(item, index, index === current)}
      </div>
    )
  }

  const renderDots = () => {
    return (
      <SliderDotsContainerComponent inside={props.dotsInside} top={props.dotsTop}>
        <SliderDotsComponent total={props.items.length} current={current} number={props.dotsNumber} theme={props.dotsTheme} onPress={onChangeDot} />
      </SliderDotsContainerComponent>
    )
  }

  const renderArrows = () => {
    if (config.consts.isScreenMobile) return null

    return (
      <div style={{ width: 50, position: 'absolute', height: '100%', backgroundColor: config.colors.white, top: 0, right: -30 }}>
        <a href="#" style={{ position: 'absolute', top: '30%', left: 10 }} onClick={(e) => { e.preventDefault(); flickity.next() }}>
          <ArrowRight width={30} height={30} />
        </a>
      </div>
    )
  }

  const renderCtaNext = () => {
    return (
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonComponent label={props.ctaNext} sand onPress={() => flickity.next()} />
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>

      {props.dots && props.dotsTop && renderDots()}

      <div style={props.block ? { position: 'relative', ...config.style.block, boxShadow: config.style.shadowWeb, backgroundColor: config.colors.secondary } : {}}>
        <Flickity
          className={'slider'}
          elementType={'div'}
          flickityRef={(f) => setFlickity(f)}
          options={{
            pageDots: false,
            prevNextButtons: false,
            cellAlign: 'left',
            adaptiveHeight: props.adaptive,
            autoPlay: props.autoplay,
            wrapAround: props.infinity || props.autoplay
          }}
          disableImagesLoaded
        >
          {props.items.map((item, index) => renderItem(item, index))}
        </Flickity>
      </div>

      {props.dots && !props.dotsTop && renderDots()}
      {props.arrows && renderArrows()}
      {props.ctaNext && renderCtaNext()}
    </div>
  )
}

// Component props
//////////////////////////////////////////////////////////////////////////

SliderComponent.propTypes = SliderComponentProps.propTypes
SliderComponent.defaultProps = SliderComponentProps.defaultProps