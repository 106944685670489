import React, { useContext } from 'react'
import config from '../config'
import { MediaContext } from '../contexts/MediaContext'
import MediaAudioComponent from './MediaAudioComponent'
import MediaVideo360Component from './MediaVideo360Component'
import MediaWebviewComponent from './MediaWebviewComponent'
import MediaVideoComponent from './MediaVideoComponent'
import MediaGalleryComponent from './MediaGalleryComponent'
import MediaTextComponent from './MediaTextComponent'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MediaManagerComponent (props) {
  const { mediaPlayer, resetMediaPlayer, setMediaPlayerPlay } = useContext(MediaContext)

  // esegue il reset di MediaContext in modo da chiudere tutti i player
  const onClose = () => resetMediaPlayer()

  // esegue la pausa del file multimediale
  const onPause = () => setMediaPlayerPlay(false)

  // esegue il play del file multimediale
  const onPlay = () => setMediaPlayerPlay(true)

  // estraggo la source valida rispetto alla componente scelta
  // qui ci sono tutte le logiche che decidono se utilizzare url
  // o player url in base al contenuto scelto.
  let sourceUrl = mediaPlayer.source
  if (mediaPlayer.source && typeof mediaPlayer.source == 'object') {
    sourceUrl = mediaPlayer.source.url

    if (mediaPlayer.type == 'video_360' && mediaPlayer.source.playerUrl) {
      sourceUrl = mediaPlayer.source.playerUrl
    }
    if (mediaPlayer.type == 'gallery') {
      sourceUrl = mediaPlayer.source.urls
    }
  }

  let language = null
  if (mediaPlayer.source?.language) language = mediaPlayer.source.language

  let label = null
  if (mediaPlayer.source?.label) label = mediaPlayer.source.label

  let isInternal = false
  if (mediaPlayer.source?.isInternal) isInternal = mediaPlayer.source.isInternal

  // ritorno la componente corretta
  if (mediaPlayer.type == 'audio') {
    return <MediaAudioComponent isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} onPause={onPause} onPlay={onPlay} {...mediaPlayer.options} />
  } else if (mediaPlayer.type == 'video_360') {
    return <MediaVideo360Component isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} {...mediaPlayer.options} />
  } else if (mediaPlayer.type == 'webview') {
    return <MediaWebviewComponent isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} {...mediaPlayer.options} />
  } else if (mediaPlayer.type == 'video') {
    return <MediaVideoComponent isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} {...mediaPlayer.options} />
  } else if (mediaPlayer.type == 'gallery') {
    return <MediaGalleryComponent isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} {...mediaPlayer.options} />
  } else if (mediaPlayer.type == 'text') {
    return <MediaTextComponent isInternal={isInternal} label={label} language={language} source={sourceUrl} play={mediaPlayer.play} onClose={onClose} {...mediaPlayer.options} />
  }

  return null
}

// Component props
//////////////////////////////////////////////////////////////////////////

MediaManagerComponent.propTypes = {

}

MediaManagerComponent.defaultProps = {

}