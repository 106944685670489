import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import ModalComponent from './ModalComponent'
import VideoComponent from './VideoComponent'
import TitleComponent from './TitleComponent'
import PaddedComponent from './PaddedComponent'
import ButtonComponent from './ButtonComponent'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MediaVideoComponent (props) {
  return (
    <ModalComponent visible={props.play}>
      <View style={config.consts.isScreenMobile ? styles.containerMobile : styles.containerDesktop}>
        <PaddedComponent style={styles.header} container={false} horizontal vertical >
          {props.name && <TitleComponent style={styles.title} label={props.name} color={config.colors.black} oneLine labelSmall={config.consts.isScreenMobile} />}
          <ButtonComponent label={t('cta_close', props.language)} danger onPress={props.onClose} />
        </PaddedComponent>
        <VideoComponent source={props.source} play={props.play} style={{ flexGrow: 1 }} native={{
          onFullscreenPlayerWillDismiss: props.onClose
        }} />
      </View>
    </ModalComponent>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  containerMobile: {
    width: '100%',
    height: '100%',
    backgroundColor: config.colors.black,
    justifyContent: 'space-between'
  },
  containerDesktop: {
    width: '80%',
    height: '80%',
    ...config.style.blockSmall,
    ...config.style.shadow
  },
  header: {
    ...config.style.mediaHeader
  },
  title: {
    width: '50%'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MediaVideoComponent.propTypes = {
  source: PropTypes.string.isRequired,
  play: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  language: PropTypes.string,
}

MediaVideoComponent.defaultProps = {
  onClose: () => {}
}