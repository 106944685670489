import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function PaddedComponent (props) {
  return (
    <View style={[styles.container, props.horizontal ? styles.containerHorizontal : {}, props.vertical ? styles.containerVertical : {}, props.container ? { maxWidth: 1269 } : null, props.style]}>
      {props.children}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    width: '100%'
  },
  containerHorizontal: {
    paddingHorizontal: 20
  },
  containerVertical: {
    paddingVertical: 20
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

PaddedComponent.propTypes = {
  style: PropTypes.any,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  container: PropTypes.bool
}

PaddedComponent.defaultProps = {
  container: true
}