import React from 'react'
import PropTypes from 'prop-types'
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native'
import LoadingComponent from './LoadingComponent'
import config from '../config'
import { ArrowRight, ArrowLeft, WalkAndLearn } from './IconComponent'


// Component code
//////////////////////////////////////////////////////////////////////////

export default function ButtonComponent (props) {

  let styleButtonExtra = styles.buttonPrimary
  if (props.secondary) styleButtonExtra = styles.buttonSecondary
  if (props.sand) styleButtonExtra = styles.buttonSand
  if (props.danger) styleButtonExtra = styles.buttonDanger
  if (props.disabled) styleButtonExtra = styles.buttonDisabled

  let styleButtonRounded = {}
  if (props.rounded) styleButtonRounded = { borderRadius: 100 }

  let iconSize = 20
  if (props.rounded) iconSize = 14

  let styleLabelExtra = styles.labelPrimary
  if (props.secondary) styleLabelExtra = styles.labelSecondary
  if (props.danger) styleLabelExtra = styles.labelDanger
  if (props.disabled) styleLabelExtra = styles.labelDisabled

  let iconColor = config.style.ButtonComponent.labelPrimary.color
  if (props.secondary) iconColor = config.style.ButtonComponent.labelSecondary.color
  if (props.disabled) iconColor = config.colors.white

  let Icon = props.arrow ? ArrowRight : ArrowLeft
  if (props.arrowType == 'brand') {
    Icon = WalkAndLearn
  }

  return (
    <TouchableOpacity
      onPress={props.onPress}
      disabled={props.disabled || props.loading}
      style={[styles.button, styleButtonExtra, styleButtonRounded, props.style]}
    >
      {props.loading ? (
        <LoadingComponent inverse />
      ) : (
        <>
          {props.arrowLeft ? <Icon width={iconSize} height={iconSize} color={iconColor} style={{ marginRight: props.label ? 15 : 0 }}/> : null }
          <Text style={[styles.label, styleLabelExtra]}>{props.label}</Text>
          {props.arrow ? <Icon width={iconSize} height={iconSize} color={iconColor} style={{ marginLeft: props.label ? 15 : 0 }}/> : null }
        </>
      )}
    </TouchableOpacity>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  button: {
    ...config.style.shadowSmall,
    paddingHorizontal: 19,
    paddingVertical: 12,
    borderRadius: 20,
    height: 50,
    justifyContent: 'center',
    width: 'auto',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonPrimary: {
    ...config.style.ButtonComponent.buttonPrimary
  },
  buttonSecondary: {
    ...config.style.ButtonComponent.buttonSecondary
  },
  buttonSand: {
    backgroundColor: config.colors.sand
  },
  buttonDanger: {
    backgroundColor: config.colors.red
  },
  buttonDisabled: {
    backgroundColor: config.colors.gray,
    shadowOpacity: 0
  },
  label: {
    ...config.style.typography,
    ...config.style.typographyBold,
    fontSize: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelPrimary: {
    ...config.style.ButtonComponent.labelPrimary
  },
  labelSecondary: {
    ...config.style.ButtonComponent.labelSecondary
  },
  labelDanger: {
    color: config.colors.white
  },
  labelDisabled: {
    color: config.colors.white
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

ButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  sand: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.any,
  arrow: PropTypes.bool,
  arrowLeft: PropTypes.bool,
  arrowType: PropTypes.string
}

ButtonComponent.defaultProps = {
  label: '',
  onPress: () => {},
  style: {}
}