import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import PaddedComponent from './PaddedComponent'
import TitleComponent from './TitleComponent'
import TextComponent from './TextComponent'
import { ArrowUp, ArrowDown } from './IconComponent'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function SwipeModalComponent (props) {
  const [visible, setVisible] = useState(props.visible)
  const [bottomTranslate, setBottomTranslate] = useState(0)

  useEffect(() => {
    if (props.visible != visible) {
      setVisible(props.visible)
    }
  }, [props.visible])

  useEffect(() => {
    if (props.visible != visible) {
      props.onChangeVisible(visible)
    }
  }, [visible])

  const saveBottomTranslate = (event) => {
    let {x, y, width, height} = event.nativeEvent.layout
    setBottomTranslate(height + (config.consts.isScreenDesktop ? 40 : 20))
  }

  return (
    <View
      style={[styles.container, { bottom: visible ? -4 : -bottomTranslate }]}
    >
      <PaddedComponent horizontal style={{ backgroundColor: 'transparent' }}>
        <View style={[styles.content, props.secondary ? styles.contentSecondary : null]}>
          <View style={styles.head}>
            <TouchableOpacity style={styles.toggle} onPress={() => setVisible(!visible)}>
              {visible ? (
                <>
                  <ArrowDown color={config.colors.black} width={30} height={30} />
                  <TextComponent label={t('cta_hide', props.language)} labelCenter labelSmall />
                </>
              ) : (
                <>
                  <ArrowUp color={config.colors.black} width={30} height={30} />
                  <TextComponent label={t('cta_discover', props.language)} labelCenter labelSmall />
                </>
              )}
            </TouchableOpacity>
            <TitleComponent label={props.title} color={config.colors.primary} labelCenter style={styles.title} />
            {props.subtitle && (<TextComponent label={props.subtitle.toUpperCase()} labelCenter style={styles.subtitle} labelSmall={config.consts.isScreenDesktop} styleText={{ letterSpacing: 2 }} />)}
          </View>
            
          <View style={styles.main} onLayout={saveBottomTranslate}>
            {props.children}
          </View>
        </View>
      </PaddedComponent>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  content: {
    ...config.style.shadow,
    width: '100%',
    backgroundColor: config.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: config.colors.primary,
    borderWidth: 4,
    borderTopLeftRadius: 60,
    borderTopRightRadius: 60,
    paddingHorizontal: 30,
    paddingTop: 15,
    paddingBottom: 45
  },
  contentSecondary: {
    borderColor: config.colors.sand,
  },
  head: {
    flexDirection: config.consts.isScreenDesktop ? 'row' : 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: config.consts.isScreenDesktop ? 'flex-start' : 'center'
  },
  toggle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: config.consts.isScreenDesktop ? 0 : 15,
    marginRight: config.consts.isScreenDesktop ? 30 : 0
  },
  title: {

  },
  subtitle: {
    marginTop: config.consts.isScreenDesktop ? 7 : 5,
    marginLeft: config.consts.isScreenDesktop ? 15 : 0
  },
  main: {
    paddingTop: config.consts.isScreenDesktop ? 15 : 30,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainVisible: {
    
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

SwipeModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onChangeVisible: PropTypes.func,
  secondary: PropTypes.bool,
  language: PropTypes.string
}

SwipeModalComponent.defaultProps = {
  visible: false,
  secondary: false,
  onChangeVisible: () => {}
}