/**
 * NOTE: index.js only for web app.
 * Mobile file is ../index.native.js
 */

import { AppRegistry } from 'react-native'
import App from './App'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css"
import 'mapbox-gl/dist/mapbox-gl.css'
import './index.css'

AppRegistry.registerComponent('walkandlearn', () => App)
AppRegistry.runApplication('walkandlearn', { rootTag: document.getElementById("root") })