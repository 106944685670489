import React, { useState, createRef, useEffect } from 'react'
import * as AudioComponentProps from './AudioComponentProps'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function AudioComponent (props) {
  const player = createRef()

  useEffect(() => {
    if (props.source && props.play) {
      player.current.play()
    } else {
      player.current.pause()
    }
  }, [props.source, props.play])

  return (
    <audio key={props.source} ref={player} controls controlsList="nodownload" style={styles.player} >
      <source src={props.source} type="audio/mp3" />
    </audio>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = {
  player: {
    width: '100%'
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

AudioComponent.propTypes = AudioComponentProps.propTypes
AudioComponent.defaultProps = AudioComponentProps.defaultProps