import React, { useState, useEffect } from 'react'
import Geolocation from 'react-native-geolocation-service'
import { requestAppPermissions } from '../libs/permissions'
import config from '../config'

export const UserContext = React.createContext()

export function UserProvider (props) {
  const [userLoaded, setUserLoaded] = useState(false)
  const [userPosition, setUserPosition] = useState(null)

  useEffect(() => {
    requestAppPermissions().then(() => {
      return Promise.all([
        geocodeUser()
      ])
    }).then((results) => {
      setUserLoaded(true)
    })
  }, [])

  const geocodeUser = () => {
    return new Promise((resolve, reject) => {
      if (config.consts.isWeb) return resolve()

      Geolocation.getCurrentPosition(
        (position) => {setUserPosition(position.coords)},
        (error) => {console.log(error)},
        { enableHighAccuracy: true, timeout: 30000, maximumAge: 60000 }
      )

      resolve()
    })
  }
  
  return (
    <UserContext.Provider value={{ userLoaded, userPosition, setUserPosition }} >
      {props.children}
    </UserContext.Provider>
  )
}
