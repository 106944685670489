import consts from './consts'
import colors from './colors'
import style from './style'
import apis from './apis'

export default {
  consts,
  colors,
  style,
  apis,
}