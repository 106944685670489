import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ImageBackground, Text } from 'react-native'
import SliderComponent from './SliderComponent'
import LinearGradientComponent from './LinearGradientComponent'
import ButtonComponent from './ButtonComponent'
import config from '../config'
import PaddedComponent from './PaddedComponent'
import TitleComponent from './TitleComponent'
import TextComponent from './TextComponent'
import RouteDetailsComponent from './RouteDetailsComponent'
import LoadingComponent from './LoadingComponent'
import { truncateString } from '../libs/helpers'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function FullSliderComponent (props) {
  const renderItem = (item, index) => {
    const style = [styles.slide, config.consts.isNative ? styles.block : null]
    // renderizzo versione temporanea se sono in modalità di caricamento
    if (item.loading) {
      return (
        <View key={index} style={style}>
          <LoadingComponent full />
        </View>
      )
    }

    return (
      <View key={item.id} style={style}>
        <ImageBackground source={{ uri: item.image }} resizeMode={'cover'} style={styles.slideImage}>
          <LinearGradientComponent colors={['rgba(0, 0, 0, 0.25)','rgba(0, 0, 0, 0.85)' ]} style={styles.slideGradient}>
            <PaddedComponent style={styles.slideContainer}>
              <RouteDetailsComponent style={styles.slideInfo} length={item.length} duration={item.duration} vehicle={item.vehicle} />
              <View style={styles.slideContent}>
                <View style={styles.slideContentText}>
                  <TitleComponent label={item.title} color={config.colors.secondary} labelLarge={config.consts.isScreenDesktop} />
                  <TextComponent label={truncateString(item.description, 200)} color={config.colors.white} style={styles.slideContentDesc} />
                </View>
                <View style={styles.slideContentActions}>
                  <ButtonComponent label={item.ctaText} onPress={item.ctaOnPress} arrow />
                </View>
              </View>
            </PaddedComponent>
          </LinearGradientComponent>
        </ImageBackground>
      </View>
    )
  }

  return (
    <SliderComponent items={props.slides || [{ loading: true }]} renderItem={renderItem} itemsToShow={1} dotsInside={40} dots dotsNumber fade block autoplay={config.consts.isScreenDesktop} />
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////
const styles = StyleSheet.create({
  block: {
    ...config.style.block
  },
  slide: {
    overflow: 'hidden',
    width: '100%',
    backgroundColor: config.colors.white,
    height: config.consts.isScreenMobile ? 550 : 650
  },
  slideImage: {
    height: '100%',
  },
  slideGradient: {
    flexDirection: 'column',
    height: '100%',
  },
  slideContainer: {
    paddingVertical: config.consts.isScreenMobile ? 40 : 60,
    paddingHorizontal: config.consts.isScreenMobile ? 20 : 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  slideInfo: {

  },
  slideContent: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: config.consts.isScreenMobile ? 80 : 100,
  },
  slideContentText: {
    width: config.consts.isScreenMobile ? '100%' : '60%'
  },
  slideContentDesc: {
    marginTop: config.consts.isScreenMobile ? 15 : 22,
  },
  slideContentActions: {
    width: 'auto',
    marginTop: 30
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

FullSliderComponent.propTypes = {
  slides: PropTypes.array,
}
