import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, ImageBackground, View, TouchableOpacity, Text, Platform } from 'react-native'
import TextComponent from './TextComponent'
import config from '../config'
import marker from '../assets/images/marker.png'
import markerOut from '../assets/images/marker-out.png'

// Component code
//////////////////////////////////////////////////////////////////////////

const HackMarker = ({ children }) =>
  Platform.select({
    web: children,
    ios: children,
    android: (
        <Text
          style={{
            lineHeight: 88, // there is some weird gap, add 40+ pixels
            backgroundColor: '#dcdcde',
          }}>
          {children}
        </Text>
    ),
})

export default function MapMarkerComponent (props) {
  return (
    <HackMarker>
      <TouchableOpacity onPress={() => props.onPress(props.index)}>
        <ImageBackground defaultSource={props.out ? markerOut : marker} source={props.out ? markerOut : marker} fadeDuration={0} resizeMode='contain' style={styles.image}>
          <View style={styles.content}>
            <TextComponent label={props.number.toString()} labelBold color={props.out ? config.colors.black : config.colors.white} />
          </View>
        </ImageBackground>
      </TouchableOpacity>
    </HackMarker>
  )
}


// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "contain",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: 30,
    height: 40
  },
  content: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: 'center',
    borderRadius: 30,
    backgroundColor: 'transparent'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MapMarkerComponent.propTypes = {
  index: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  out: PropTypes.bool,
  onPress: PropTypes.func
}

MapMarkerComponent.defaultProps = {
  onPress: () => {}
}