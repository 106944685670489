import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function SliderDotsContainerComponent (props) {
  const containerStyle = props.inside ? styles.containerInside : (props.top ? styles.containerNotInsideTop : styles.containerNotInside)
  const containerInsidePositionStyle = props.inside ? { bottom: props.inside } : null

  return (
    <View style={[styles.container, containerStyle, containerInsidePositionStyle, props.style]}>
      {props.children}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  containerInside: {
    position: 'absolute',
    bottom: 0
  },
  containerNotInside: {
    marginTop: config.consts.isScreenMobile ? 20 : 40
  },
  containerNotInsideTop: {
    marginBottom: config.consts.isScreenMobile ? 30 : 40
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

SliderDotsContainerComponent.propTypes = {
  inside: PropTypes.number,
  style: PropTypes.any,
  top: PropTypes.bool
}

SliderDotsContainerComponent.defaultProps = {
  inside: false
}