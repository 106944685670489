import React, { useState, useEffect } from 'react'
import { getItem } from '../libs/storage'
import { availableLocales, defaultLocale, currentLocale, setCurrentLocale } from '../libs/locales'

export const LocalesContext = React.createContext()

export function LocalesProvider (props) {
  const [localesLoaded, setLocalesLoaded] = useState(false)

  // eseguo il controllo dei dati dell'utente al primo avvio
  useEffect(() => {
    const getLocalesData = async () => {
      const localesData = await getItem('localesData')

      if (localesData && availableLocales.includes(localesData.currentLocale)) {
        setCurrentLocale(localesData.currentLocale)
      } else {
        const deviceLocale = 'it' // TODO: Leggere lingua del dispositivo
        setCurrentLocale(availableLocales.includes(deviceLocale) ? deviceLocale : defaultLocale)
      }

      setLocalesLoaded(true)
    }

    getLocalesData()
  }, [])

  return (
    <LocalesContext.Provider value={{ localesLoaded, currentLocale }} >
      {props.children}
    </LocalesContext.Provider>
  )
}
