export const getItem = async (name) => {
  const item = window.localStorage.getItem(name)
  try {
    return item ? JSON.parse(item) : null
  } catch (e) {
    console.error(e)
    return null
  }
}

export const setItem = async (name, item) => {
  await window.localStorage.setItem(name, JSON.stringify(item))
}

export const removeItem = async (name) => {
  await window.localStorage.removeItem(name)
}

export const reset = async () => {
  await window.localStorage.clear()
}
