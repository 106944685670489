import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ScrollView } from 'react-native'
import ButtonIconComponent from './ButtonIconComponent'
import TextComponent from './TextComponent'
import { MediaContext } from '../contexts/MediaContext'
import { openExternalLink } from '../libs/helpers'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function RouteStepContentComponent (props) {
  const { setMediaPlayer, toggleMediaPlayer } = useContext(MediaContext)

  const manageItemPlayer = (file) => {
    const mediaType = typologyMediaPlayer(file.typology)
    if (!mediaType) return

    if (mediaType == 'link') {
      openExternalLink(file.url)
      return
    }

    setMediaPlayer({ isInternal: props.isInternal, label: props.step.label, language: props.language, url: file.url, urls: file.urls, playerUrl: file.playerUrl }, mediaType, { name: file.name })
    toggleMediaPlayer(true)
  }

  const manageItemAudio = (file) => {
    setMediaPlayer({ isInternal: props.isInternal, label: props.step.label, language: props.language, url: file.url, urls: file.urls, playerUrl: file.playerUrl }, 'audio', { name: file.name, stepsTracks: [[file]] })
    toggleMediaPlayer(true)
  }

  const onPressFile = (file) => {
    if (file.typology === 1) {
      if (props.customAudioManager) {
        props.customAudioManager(file)
      } else {
        manageItemAudio(file)
      }
    } else {
      manageItemPlayer(file)
    }    
  }

  return (
    <View style={[styles.container, props.style]}>
      {props.scrollableDescription ? (
        <ScrollView style={{ maxHeight: 100 }}>
          <TextComponent style={styles.slideContentDescription} label={props.step.description} labelCenter={props.centered} />
        </ScrollView>
      ) : (
        <TextComponent style={styles.slideContentDescription} label={props.step.description} labelCenter={props.centered} />
      )}
      {props.step.files.length > 0 && (
        <View style={styles.buttons}>
          {props.step.files.map((file, index) => <ButtonIconComponent style={styles.button} key={index} icon={typologyIcon(file.typology)} label={tipologyLabel(file.typology, props.language)} onPress={() => onPressFile(file)} />)}
        </View>
      )}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  buttons: {
    marginTop: 40,
    flexDirection: config.consts.isScreenMobile ? 'column' : 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  button: {
    marginRight: config.consts.isScreenMobile ? 0 : 15,
    marginBottom: config.consts.isScreenMobile ? 10 : 0,
    width: config.consts.isScreenMobile ? '100%' : 'auto'
  }
})

// Component helpers
//////////////////////////////////////////////////////////////////////////

function typologyMediaPlayer(typology) {
  switch (typology) {
    case 1 : 
      return "audio"
    case 2 : 
      return "video"
    case 3 : 
      return "link"
    case 4 : 
      return "link"
    case 5 : 
      return "webview"
    case 6 : 
      return  "webview"
    case 7 : 
      return "video_360"
    case 8 : 
      return "gallery"
  }
}

function typologyIcon(typology) {
  switch (typology) {
    case 1 :
      return "audio"
    case 2 : 
      return  "video"
    case 3 : 
      return  "link" // doc pdf
    case 4 : 
      return  "link" // link
    case 5 : 
      return  "video"
    case 6 : 
      return  "virtual_tour"
    case 7 : 
      return  "video"
    case 8 : 
      return  "gallery"
  }
}

function tipologyLabel(typology, language = null) {
  return t(`cta_play_type_${typology}`, language)
  // switch (typology) {
  //   case 1 :
  //     return "Ascolta l'audio"
  //   case 2 : 
  //     return  "Guarda il video"
  //   case 3 : 
  //     return  "Scarica il pdf"
  //   case 4 : 
  //     return  "Vai al Link"
  //   case 5 : 
  //     return  "Guarda il video 360"
  //   case 6 : 
  //     return  "Virtual tour"
  //   case 7 : 
  //     return  "Guarda il video 360"
  //   case 8 : 
  //     return  "Guarda le immagini"
  // }
}

// Component props
//////////////////////////////////////////////////////////////////////////

RouteStepContentComponent.propTypes = {
  style: PropTypes.any,
  step: PropTypes.object,
  stepIndex: PropTypes.number,
  customAudioManager: PropTypes.func,
  centered: PropTypes.bool,
  scrollableDescription: PropTypes.bool,
  language: PropTypes.string // lingua da utilizzare nelle traduzioni (per forzare al posto di quella di sistema)
}