import React from 'react'
import { Player, ControlBar, BigPlayButton } from 'video-react'

import * as VideoComponentProps from './VideoComponentProps'

// Component code
//////////////////////////////////////////////////////////////////////////
export default class VideoComponent extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      play: false
    }

    this.player = React.createRef()
  }

  componentDidMount() {
    if (this.props.autoplay) {
      setTimeout(() => { if (this.props.play) { this.play() } }, 3500)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.play && !this.props.play) {
      this.pause()
    }

    if (!prevProps.play && this.props.play) {
      this.play()
    }
  }

  play() {
    this.setState({ play: true }, () => this.player.play())
  }

  pause() {
    this.setState({ play: false }, () => this.player.pause())
  }

  render() {
    let style = { display: 'flex', alignItems: 'center', opacity: (this.props.hidden && !this.state.play) ? 0 : 1 }
    if (this.props.style) style = Object.assign({}, style, this.props.style)

    return (
      <div style={style}>
        <Player
          poster={this.props.poster}
          src={this.props.source}
          loop={this.props.loop}
          muted={this.props.mute}
          ref={(player) => { this.player = player }}
          fluid={!this.props.background}
          width={'100%'}
          height={'150%'}
        >
          <BigPlayButton position="center" />
          <ControlBar disableDefaultControls={this.props.background} />
        </Player>
      </div>
    )
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

VideoComponent.propTypes = VideoComponentProps.propTypes
VideoComponent.defaultProps = VideoComponentProps.defaultProps