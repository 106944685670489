import React, { useMemo, useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'
import AudioComponent from './AudioComponent'
import ButtonComponent from './ButtonComponent'
import SpacerComponent from './SpacerComponent'
import SwipeModalComponent from './SwipeModalComponent'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MediaAudioComponent (props) {
  const [index, setIndex] = useState(0)
  const [isLast, setIsLast] = useState(false)
  const stepTracks = props.stepsTracks.filter((t) => t.length > 0)

  useEffect(() => {
    stepTracks.map((t, i) => {
      if (t[0]?.url == props.source) {
        setIndex(i)
      }
    })
  }, [props.source])

  useEffect(() => {
    if (index >= stepTracks.length - 1) {
      setIsLast(true)
    } else {
      setIsLast(false)
    }
  }, [index])

  // creo un array di traccie a partire da stepTracks
  const tracksData = useMemo(() => {
    let tracks = []
    stepTracks.map((stepTracks, index) => {
      tracks = tracks.concat(stepTracks.map((s) => { s.step = index; return s }))
    })
    return tracks
  }, [stepTracks])
  
  const onTrackChange = (data) => {
    if (data) props.onStepsTrackChange(data.step)
  }

  const onPressAction = () => {
    if (isLast) {
      props.onClose()
    } else {
      props.onStepsTrackChange(index + 1)
    }
  }

  return (
    <SwipeModalComponent
      visible={true}
      title={props.name}
      subtitle={props.label || `${t('label_route_step', props.language)} ${index + 1}`}
      secondary
      language={props.language}
    >
      {config.consts.isScreenDesktop ? (
        <View style={styles.contentDesktop}>
          <AudioComponent style={{ width: '100%' }} source={props.source} title={props.name} play={props.play} mute={props.mute} tracks={tracksData} onTrackChange={onTrackChange} onTrackPause={props.onPause} onTrackPlay={props.onPlay} />
          <ButtonComponent style={{ marginLeft: 30 }} label={isLast ? t(`cta_close_audio${props.isInternal ? '_internal' : ''}`, props.language) : t(`cta_next_audio${props.isInternal ? '_internal' : ''}`, props.language)} arrow={!isLast} onPress={onPressAction} />
        </View>
      ) : (
        <View style={styles.contentMobile}>
          <AudioComponent style={{ width: '100%' }} source={props.source} title={props.name} play={props.play} mute={props.mute} tracks={tracksData} onTrackChange={onTrackChange} onTrackPause={props.onPause} onTrackPlay={props.onPlay} />
          <SpacerComponent size={2} />
          <ButtonComponent style={{ width: 'auto' }} sand label={isLast ? t(`cta_close_audio${props.isInternal ? '_internal' : ''}`, props.language) : t(`cta_next_audio${props.isInternal ? '_internal' : ''}`, props.language)} arrow={!isLast} onPress={onPressAction} />
        </View>
      )}
    </SwipeModalComponent>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  contentDesktop: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentMobile: {
    width: '100%',
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MediaAudioComponent.propTypes = {
  play: PropTypes.bool,
  mute: PropTypes.bool,
  onClose: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onStepsTrackChange: PropTypes.func,
  language: PropTypes.string,
}

MediaAudioComponent.defaultProps = {
  onClose: () => {},
  onPause: () => {},
  onPlay: () => {},
  onStepsTrackChange: () => {}
}