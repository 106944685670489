import React, { useState } from 'react'
import axios from 'axios'
import { getItem } from '../libs/storage'
import config from '../config'

let cache = {}

function getCachedResponse(apiKey, params) {
  const paramsString = JSON.stringify(params)
  if (!cache[apiKey]) return null

  return cache[apiKey][paramsString]
}

function setCachedResponse(apiKey, params, responseData) {
  const paramsString = JSON.stringify(params)
  if (!cache[apiKey]) cache[apiKey] = {}

  cache[apiKey][paramsString] = responseData
  return true
}

/**
 * @function useApi
 * Hook per la gestione delle API.
 */
export default function useApi(apiKey) {
  const [requestRunning, setRequestRunning] = useState(false)

  // ottengo i dati dell'api da utilizzare e verifico la loro esistenza
  const apiData = config.apis[apiKey]
  if (!apiData) throw new Error('apiKey value not valid')

  // definisco la funzione di esecuzione della richiesta
  const requestSend = (params = {}) => {
    return new Promise((resolve, reject) => {
      // verifico che non ci siano altre chiamate in esecuzioni
      if (requestRunning) return reject(new Error('request already running'))

      // memorizzo lo stato della richiesta
      setRequestRunning(true)

      // gestisco l'eventuale risposta già in cache
      if (apiData.cached) {
        const cachedResponse = getCachedResponse(apiKey, params)
        if (cachedResponse) {
          setRequestRunning(false)
          resolve(cachedResponse)
          return
        }
      }

      // prendo i dati utili per la richiesta autenticata (vedi AuthContext)
      getItem('authData').then((authData) => {
        getItem('debug').then((debug) => {
          if (debug) { params.debug = 1 }

          let method = (apiData.method || 'GET').toUpperCase()
          let url = `${config.apis.baseUrl}${apiData.path}?`
          if (method === 'GET') {
            Object.keys(params).forEach((paramKey) => {
              url = `${url}${paramKey}=${params[paramKey]}&`
            })
          }

          const requestParams = {
            method: method,
            url: url,
            data: method === 'GET' ? undefined : params,
            timeout: 30000,
            headers: {
              'Content-Type': 'application/json'
            }
          }

          // invio la richiesta al server
          axios(requestParams).then((response) => {
            setRequestRunning(false)
            if (apiData.cached) setCachedResponse(apiKey, params, response.data)
            resolve(response.data)
          }).catch((err) => {
            console.error(err)
            setRequestRunning(false)
            resolve({ result: false })
          })
        })
      })
    })
  }

  return [requestRunning, requestSend]
}
