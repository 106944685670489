import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity} from 'react-native'
import { Audio, AudioOff, Time, Distance, Bicycle, Walk, Drive } from './IconComponent'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function ButtonCircleComponent (props) {
  return (
    <TouchableOpacity onPress={props.onPress} style={props.style}>
      <View>
        {props.icon === 'audio' && <Audio color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'audio-off' && <AudioOff color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'time' && <Time color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'distance' && <Distance color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'bicycle' && <Bicycle color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'walk' && <Walk color={props.color} width={props.size} height={props.size} />}
        {props.icon === 'drive' && <Drive color={props.color} width={props.size} height={props.size} />}
      </View>
    </TouchableOpacity>
  )
}

// Component props
//////////////////////////////////////////////////////////////////////////

ButtonCircleComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.any,
}

ButtonCircleComponent.defaultProps = {
  icon: '',
  size: 40,
  onPress: () => {},
  style: {}
}