import PropTypes from 'prop-types'

export const propTypes = {
  markers: PropTypes.array,
  currentMarker: PropTypes.number,
  onPressMarker: PropTypes.func,
  onNearCurrentMarker: PropTypes.func,
  onPressClose: PropTypes.func,
  vehicle: PropTypes.number
}

export const defaultProps = {
  vehicle: 3,
  onPressMarker: () => {},
  onNearCurrentMarker: () => {},
  onPressClose: () => {}
}