import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import SliderComponent from './SliderComponent'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function BoxSliderComponent (props) {
  const itemsToShow = config.consts.isScreenMobile ? 1 : 3

  const renderItem = (item, index) => {
    return (
      <View key={item.loading ? index : item.id} style={{ width:'100%', paddingRight: itemsToShow > 1 ? 45 : 0 }}>
        {props.renderItem(item.loading ? null : item)}
      </View>
    )
  }

  return (
    <SliderComponent items={props.items || [{ loading: true }, { loading: true }, { loading: true }]} renderItem={renderItem} itemsToShow={itemsToShow} dots dotsTheme='dark' arrows infinity />
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
})

// Component props
//////////////////////////////////////////////////////////////////////////

BoxSliderComponent.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func.isRequired
}