import React, { useMemo } from 'react'
import ReactMapboxGl, { Marker, GeoJSONLayer } from 'react-mapbox-gl'
import MapContainerComponent from './MapContainerComponent'
import MapMarkerComponent from './MapMarkerComponent'
import * as MapComponentProps from './MapComponentProps'
import * as MapHelpers from '../libs/maps'
import config from '../config'

const Map = ReactMapboxGl({ accessToken: config.consts.mapboxAccessToken, interactive: true })

export default function MapComponent(props) {
  const isLoading = !(props.markers && props.markers.length > 0)

  // save the geojson line object to show street between markers
  const geoJsonLine = useMemo(() => MapHelpers.getCoordinatesGeoJsonLine(props.markers || []), [props.markers])

  // memorizzo l'oggetto bound per centrare i punti sulla mappa
  const bound = useMemo(() => {
    return props.markers ? MapHelpers.getCoordinatesBound(props.markers) : {}
  }, [props.markers])

  return (
    <MapContainerComponent loading={isLoading}>
      {props.markers && (
        <Map
          style={MapHelpers.getMapDefaultStyle()}
          containerStyle={{ height: '100%', width: '100%' }}
          center={MapHelpers.getMapCenter(props.markers, props.currentMarker)}
          animationOptions={{ duration: 1000 }}
          fitBounds={[bound.ne, bound.sw]}
          fitBoundsOptions={{ padding: { top: 40, bottom: config.consts.isScreenDesktop ? 140 : 40, left: 40, right: 40 } }}
          zoom={[15]}
        >
          {props.markers && props.markers.map((marker, index) => {
            return (
              <Marker
                key={index}
                coordinates={MapHelpers.getCoordinateArray(marker)}
                anchor="bottom">
                <MapMarkerComponent index={index} number={index + 1} onPress={props.onPressMarker} out={index <= props.currentMarker} />
              </Marker>
            )
          })}

          <GeoJSONLayer
            data={geoJsonLine}
            linePaint={{ 'line-color': ['get', 'color'], 'line-width': 2 }}
            lineLayout={{ 'line-cap': 'round' }}
          />
        </Map>
      )}
    </MapContainerComponent>
  )
}

// Component props
//////////////////////////////////////////////////////////////////////////

MapComponent.propTypes = MapComponentProps.propTypes
MapComponent.defaultProps = MapComponentProps.defaultProps
