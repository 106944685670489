import React, { useState, useEffect, useContext } from 'react'
import { useIsFocused } from '@react-navigation/native'
import useApi from '../hooks/useApiHook'
import useContainerScrollPosition from '../hooks/useContainerScrollPositionHook'
import { MediaContext } from '../contexts/MediaContext'
import { UiContext } from '../contexts/UiContext'
import ContainerComponent from '../components/ContainerComponent'
import CardComponent from '../components/CardComponent'
import BoxSliderComponent from '../components/BoxSliderComponent'
import FooterComponent from '../components/FooterComponent'
import SpacerComponent from '../components/SpacerComponent'
import MapComponent from '../components/MapComponent'
import HeroComponent from '../components/HeroComponent'
import PaddedComponent from '../components/PaddedComponent'
import BoxComponent from '../components/BoxComponent'
import RouteStepsSliderComponent from '../components/RouteStepsSliderComponent'
import { paramsGenerator, titleGenerator } from '../libs/navigation'
import config from '../config'
import { t } from '../libs/locales'

const SCROLL_AUDIO_AUTOPLAY = config.consts.isScreenDesktop ? 650 : 500

export default function RouteView(props) {
  const { route_permalink, data } = props.route.params
  const [hero, setHero] = useState(null)
  const [markers, setMarkers] = useState(null)
  const [steps, setSteps] = useState(null)
  const [related, setRelated] = useState(null)
  const [isInternal, setIsInternal] = useState(true)
  const [language, setLanguage] = useState(null)
  const [currentStep, setCurrentStep] = useState(null)
  const [areasIndexRequestStatus, areasIndexRequestSend] = useApi('areasIndex')
  const [charactersIndexRequestStatus, charactersIndexRequestSend] = useApi('charactersIndex')
  const [routesShowRequestStatus, routesShowRequestSend] = useApi('routesShow')
  const [charactersShowRequestStatus, charactersShowRequestSend] = useApi('charactersShow')
  const [scrollPosition, scrollPositionUpdater] = useContainerScrollPosition()
  const { play: mediaPlay, resetMediaPlayer, setMediaPlayer, toggleMediaPlayer } = useContext(MediaContext)
  const { setShowHeader } = useContext(UiContext)
  const isFocused = useIsFocused()

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    loadCurrentStepFromUrl()
    loadData()
    resetMediaPlayer()
    return () => resetMediaPlayer()
  }, [])

  useEffect(() => {
    if (isInternal && config.consts.isWeb) {
      setShowHeader(false)
    }

    return () => {
      setShowHeader(true)
    }
  }, [isInternal])

  // NON FUNZIONANTE - SERVE UNA INTERAZIONE DA PARTE DELL'UTENTE PER ATTIVARE L'AUDIO
  // useEffect(() => {
  //   if (!isInternal || !steps) return

  //   const urlParams = new URLSearchParams(window.location.search)
  //   let currentStep = 0
  //   if (urlParams.has('step')) currentStep = parseInt(urlParams.get('step'))

  //   const step = steps[currentStep]
  //   console.log('step', step)

  //   const file = step.files[0]
  //   if (!file || file.typology !== 1) return

  //   setMediaPlayer({ isInternal: true, label: step.label, language: language, url: file.url, urls: file.urls, playerUrl: file.playerUrl }, 'audio', { name: file.name, stepsTracks: [[file]] })
  //   toggleMediaPlayer(true)
  // }, [isInternal, steps])

  //////////////////////////////////////////////////////////////////////////

  const loadCurrentStepFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('step')) {
      const step = parseInt(urlParams.get('step'))
      setTimeout(() => setCurrentStep(step - 1), 1000)
    } else {
      setCurrentStep(0)
    }
  }

  const loadData = async () => {
    if (areasIndexRequestStatus || charactersIndexRequestStatus || routesShowRequestStatus || charactersShowRequestStatus) return

    // scarico dati del percorso
    const responseRoute = await routesShowRequestSend({ route_permalink: route_permalink })
    const route = responseRoute.result ? responseRoute.payload.route : data
    const routeIsInternal = route.typology == 'internal'
    const routeLanguage = route.language || 'it'
    const steps = responseRoute.result ? responseRoute.payload.route_steps.sort((a, b) => a.position - b.position) : []

    props.navigation.setOptions({ title: titleGenerator(route.name) })
    if (config.consts.isNative) props.navigation.setParams({ screen_title: titleGenerator(route.name) })

    // scarico dati del personaggio
    const responseCharacter = await charactersShowRequestSend({ character_uuid: route.character_uuid })
    const character = responseCharacter.result ? responseCharacter.payload.character : null

    const markersData = steps.map((s) => ({
      geo_lat: s.geo_lat,
      geo_lng: s.geo_lng,
      position: s.position
    }))

    const stepsData = steps.map((s) => ({
      id: s.uuid,
      title: s.name,
      label: s.label,
      description: s.description,
      image: s.url_image_preview.original,
      files: s.files.sort((a, b) => a.position - b.position).map((file) => ({
        name: file.name,
        typology: file.typology,
        url: file.url,
        urls: file.urls,
        playerUrl: file.player_url
      }))
    }))

    let imagePreview = route.url_image_preview.desktop
    if (config.consts.isScreenDesktop && route.url_image_preview_desktop.desktop) {
      imagePreview = route.url_image_preview_desktop.desktop
    }

    setHero([{
      id: route.uuid,
      title: route.name,
      text: route.description,
      image: imagePreview,
      video: route.url_video_trailer,
      ctaText: t('cta_start_walk', routeLanguage),
      ctaBig: true,
      ctaOnPress: config.consts.isScreenMobile && !routeIsInternal ? () => onGoToRouteNavigator(route) : null,
      ctaArrowType: 'brand',
      inner: routeIsInternal ? null : {
        duration: route.duration_label,
        length: route.length_label,
        vehicle: route.vehicle,
      },
      character: character ? {
        name: character.name,
        image: character.url_image_preview.mobile
      } : null
    }])

    setMarkers(markersData)
    setSteps(stepsData)

    // carico dati del resto della pagina
    Promise.all([
      areasIndexRequestSend({ per_page: 8, group_uuid: config.consts.group }),
      charactersIndexRequestSend({ per_page: 8, group_uuid: config.consts.group }),
    ]).then((responses) => {
      const areas = (responses[0].result ? responses[0].payload.data : []).map((a) => { a.id = a.uuid; return a })
      const characters = (responses[1].result ? responses[1].payload.data : []).map((a) => { a.id = a.uuid; return a })
      setRelated({ areas, characters })
    })

    // set is internal
    setIsInternal(routeIsInternal)

    // set language
    setLanguage(routeLanguage)
  }

  const onGoToRoute = (data) => {
    props.navigation.push('Route', paramsGenerator({ route_permalink: data.permalink, native: { data, screen_title: data?.name } }))
  }

  const onGoToArea = (data) => {
    props.navigation.push('Area', paramsGenerator({ area_permalink: data.permalink, native: { data, screen_title: data.name } }))
  }

  const onGoToCharacter = (data) => {
    props.navigation.push('Character', paramsGenerator({ character_permalink: data.permalink, native: { data, screen_title: data.name } }))
  }

  const onGoToRouteNavigator = (data) => {
    props.navigation.push('RouteNavigator', paramsGenerator({ route_permalink: data.permalink, native: { data, screen_title: data.name } }))
  }

  const onChangeStep = (stepIndex) => {
    setCurrentStep(stepIndex)
  }

  const onGoBack = () => {
    props.navigation.goBack()
  }

  //////////////////////////////////////////////////////////////////////////

  const renderCardItem = (item, itemType, itemVertical = false) => {
    let onPressFunc = null
    if (itemType === 'area') onPressFunc = item?.routes.length > 1 ? onGoToArea : () => onGoToRoute({ permalink: item.routes_permalinks[0] })
    if (itemType === 'character') onPressFunc = item?.routes.length > 1 ? onGoToCharacter : () => onGoToRoute({ permalink: item.routes_permalinks[0] })
    return <CardComponent language={language} loading={!item} title={item?.name} image={item?.url_image_preview?.mobile} onPress={() => onPressFunc(item)} vertical={itemVertical} />
  }

  return (
    <ContainerComponent scrollable onScroll={scrollPositionUpdater}>
      <HeroComponent language={language} slides={hero} play={isFocused && !mediaPlay && scrollPosition < SCROLL_AUDIO_AUTOPLAY} onPressBack={props.navigation.canGoBack() ? onGoBack : null} />
      <SpacerComponent size={5} />
      <PaddedComponent horizontal>
        {isFocused && !isInternal && <MapComponent markers={markers} currentMarker={currentStep} onPressMarker={onChangeStep} timeout={1500} />}
        {config.consts.isScreenMobile && !isInternal ? <SpacerComponent size={1} /> : null}
        <RouteStepsSliderComponent isInternal={isInternal} currentStep={currentStep} onChangeStep={onChangeStep} steps={steps} autoplay={false && scrollPosition > SCROLL_AUDIO_AUTOPLAY} underMap language={language} />
      </PaddedComponent>
      <SpacerComponent size={5} />
      {isFocused && config.consts.isWeb && !isInternal ? (
        <>
          <PaddedComponent horizontal>
            <BoxComponent title={t('label_areas', language)}>
              <BoxSliderComponent items={related?.areas} renderItem={(item) => renderCardItem(item, 'area')} />
            </BoxComponent>
          </PaddedComponent>
          <SpacerComponent size={5} />
          <PaddedComponent horizontal>
            <BoxComponent title={t('label_characters', language)}>
              <BoxSliderComponent items={related?.characters} renderItem={(item) => renderCardItem(item, 'character', true)} />
            </BoxComponent>
          </PaddedComponent>
          <SpacerComponent size={5} />
        </>
      ) : (
        <SpacerComponent size={20} />
      )}
      <FooterComponent />
    </ContainerComponent>
  )

}