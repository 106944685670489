import React, { useEffect, useState, useContext, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import mapboxgl from 'mapbox-gl'
import ReactMapboxGl, { Marker, GeoJSONLayer } from 'react-mapbox-gl'
import MapboxDirectionsFactory from '@mapbox/mapbox-sdk/services/directions'
import {lineString as makeLineString} from '@turf/helpers'
import { UserContext } from '../contexts/UserContext'
import MapMarkerComponent from './MapMarkerComponent'
import * as MapHelpers from '../libs/maps'
import { debounce } from '../libs/helpers'
import config from '../config'
import MapNavigatorHeaderComponent from './MapNavigatorHeaderComponent'
import * as MapNavigatorComponentProps from './MapNavigatorComponentProps'


const Map = ReactMapboxGl({ accessToken: config.consts.mapboxAccessToken, interactive: true })
const mapboxDirectionsClient = MapboxDirectionsFactory({ accessToken: config.consts.mapboxAccessToken })

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MapNavigatorComponent (props) {
  const { userPosition, setUserPosition } = useContext(UserContext) 
  const [suggestedRoute, setSuggestedRoute] = useState(null)
  const [vehicle, setVehicle] = useState(props.vehicle)
  const [distance, setDistance] = useState(null)
  const [near, setNear] = useState(0.01)
  const [map, setMap] = useState(null)
  const [mapInitialized, setMapInitialized] = useState(false)
  const loadSuggestedRouteSemaphore = useRef(false)

  useEffect(() => {
    loadDistance(props.markers, props.currentMarker)
    loadSuggestedRoute(props.markers, props.currentMarker)
  }, [props.markers, props.currentMarker, userPosition])

  useEffect(() => {
    loadSuggestedRoute(props.markers, props.currentMarker)
  }, [props.markers, props.currentMarker, vehicle])

  useEffect(() => {
    if (userPosition && distance != null && distance < near) props.onNearCurrentMarker()
  }, [distance, userPosition])

  useEffect(() => {
    if (!mapInitialized && map && props.markers && props.markers[props.currentMarker]) {
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
  
      geolocate.on('geolocate', (position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        manageUserMovement({ coords: { latitude, longitude } })
      })
      
      map.addControl(geolocate)
      map.setCenter(MapHelpers.getMapCenter(props.markers, props.currentMarker))

      setMapInitialized(true)
    }
  }, [map, props.markers, props.currentMarker, mapInitialized])

  //////////////////////////////////////////////////////////////////////////

  // funzione che scarica il percorso da consigliare all'utente nel movimento.
  const loadSuggestedRoute = (markers, currentMarker) => {
    // utilizzo un semaforo per limitarmi a fare al massimo una richiesta ogni 10 secondi
    if (loadSuggestedRouteSemaphore.current && Date.now() - loadSuggestedRouteSemaphore.current < 10000) return
    loadSuggestedRouteSemaphore.current = Date.now()

    let waypoints = []
    if (userPosition) waypoints.push({ coordinates: [userPosition.longitude, userPosition.latitude] })
    waypoints = waypoints.concat(markers.filter((m, i) => i >= currentMarker).map((m) => ({ coordinates: MapHelpers.getCoordinateArray(m) })))
    if (waypoints.length < 2) return setSuggestedRoute(null)

    const reqOptions = {
      waypoints: waypoints,
      profile: vehicleToProfile(vehicle),
      geometries: 'geojson',
    }
    
    mapboxDirectionsClient.getDirections(reqOptions).send().then((response) => {
      if (response.body && response.body.code == 'Ok') {
        setSuggestedRoute(makeLineString(response.body.routes[0].geometry.coordinates))
      }
    }).catch((err) => console.log(err))
  }

  // funzione che calcola se l'utente è abbastanza vicino al current index e, se lo è, avvisa l'esterno
  const loadDistance = (markers, currentMarker) => {
    if (!userPosition) return

    const currentMarkerObj = markers.filter((m, i) => i >= currentMarker)[0]
    if (!currentMarkerObj) return

    const newDistance = MapHelpers.getDistanceFromLatLonInKm(userPosition.latitude, userPosition.longitude, currentMarkerObj.geo_lat, currentMarkerObj.geo_lng)
    setDistance(newDistance)
  }

  const manageUserMovement = debounce((data) => {
    setUserPosition(data.coords)
  }, 1000)

  //////////////////////////////////////////////////////////////////////////

  return (
    <View style={styles.container}>
      <Map
        style={MapHelpers.getMapDefaultStyle()}
        containerStyle={{ height: '100%', width: '100%' }}
        animationOptions={{ duration: 1000 }}
        onStyleLoad={(map) => setMap(map)}
      >
        {props.markers && props.markers.map((marker, index) => {
          return (
            <Marker
              key={index}
              coordinates={MapHelpers.getCoordinateArray(marker)}
              anchor="bottom">
              <MapMarkerComponent index={index} number={index + 1} onPress={props.onPressMarker} out={index < props.currentMarker} />
            </Marker>
          )
        })}

        <GeoJSONLayer
          data={suggestedRoute}
          linePaint={{
            'line-color': '#000',
            'line-width': 2
          }}
        />
      </Map>
        
      <MapNavigatorHeaderComponent vehicle={vehicle} distance={distance} near={near} userPosition={userPosition} onChangeNear={setNear} onPressClose={props.onPressClose} onPressVehicle={setVehicle} />
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: config.consts.screenHeight,
    position: 'relative'
  },
  map: {
    flex: 1
  }
})

function vehicleToProfile(number) {
  switch (number) {
    case 1 :
      return "driving"
    case 2 : 
      return  "cycling"
    case 3 : 
      return  "walking"
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

MapNavigatorComponent.propTypes = MapNavigatorComponentProps.propTypes
MapNavigatorComponent.defaultProps = MapNavigatorComponentProps.defaultProps