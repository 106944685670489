import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, ImageBackground, View, TouchableOpacity } from 'react-native'
import LinearGradientComponent from './LinearGradientComponent'
import SliderComponent from './SliderComponent'
import TextComponent from './TextComponent'
import PaddedComponent from './PaddedComponent'
import TitleComponent from './TitleComponent'
import ButtonComponent from './ButtonComponent'
import VideoComponent from './VideoComponent'
import ButtonCircleComponent from './ButtonCircleComponent'
import RouteDetailsComponent from './RouteDetailsComponent'
import HeroCharacterComponent from './HeroCharacterComponent'
import LoadingComponent from './LoadingComponent'
import { MediaContext } from '../contexts/MediaContext'
import { truncateString } from '../libs/helpers'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function HeroComponent(props) {
  const { mute, setMute, setMediaPlayer, toggleMediaPlayer } = useContext(MediaContext)

  const onPressReadMore = (name, text) => {
    setMediaPlayer(text, 'text', { name })
    toggleMediaPlayer()
  }

  const renderSliderItem = (item, index, active) => {
    // renderizzo versione temporanea se sono in modalità di caricamento
    if (item.loading) {
      return (
        <View key={index} style={styles.container}>
          <View style={[styles.content, { justifyContent: 'center', alignItems: 'center', paddingVertical: 0 }]}>
            <LoadingComponent full />
          </View>
        </View>
      )
    }

    return (
      <View key={item.id} style={styles.container}>
        <ImageBackground source={{ uri: item.image }} style={styles.image}>
          {config.consts.isScreenDesktop && item.video && (
            <VideoComponent
              style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', height: '100%', zIndex: 0, transform: 'translate(-50%, -50%)' }}
              poster={item.image}
              source={item.video}
              play={props.play && active}
              mute={mute}
              hidden
              loop
              background
              autoplay
            />
          )}
          <LinearGradientComponent colors={['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0.5)', config.colors.background]} style={styles.gradient}>
            {config.consts.isWeb && <PaddedComponent style={styles.actions} horizontal>
              {props.onPressBack && config.consts.isNative ? <ButtonComponent label={t('cta_back', props.language)} onPress={props.onPressBack} arrowLeft secondary /> : <View />}
              {item.video ? <ButtonCircleComponent icon={mute ? 'audio-off' : 'audio'} onPress={() => setMute(!mute)} size={40} color={config.colors.black} /> : <View />}
            </PaddedComponent>}
            <PaddedComponent style={styles.content} horizontal>
              <View style={styles.contentInner}>
                <TitleComponent label={item.title} color={config.colors.black} labelLarge={config.consts.isScreenDesktop} />
                {!!item.inner && <View style={styles.icons}><RouteDetailsComponent language={props.language} length={item.inner.length} duration={item.inner.duration} vehicle={item.inner.vehicle} theme={'dark'}/></View>}
                {!!item.subtitle && <TextComponent style={styles.subtitle} styleText={{ textTransform: 'uppercase', letterSpacing: 5 }} label={item.subtitle}/>}
                {!!item.text && (
                  <>
                    <TextComponent label={truncateString(item.text, config.consts.isScreenDesktop && !item.textComplete ? 200 : 999)} style={styles.text} />
                    {config.consts.isScreenDesktop && item.text.length > 200 && !item.textComplete && <TouchableOpacity onPress={() => onPressReadMore(item.title, item.text)}><TextComponent label="Leggi di più" color={config.colors.blackLight} styleText={{ marginTop: 7, fontSize: 16, textDecoration: 'underline' }} labelBold /></TouchableOpacity>}
                  </>
                )}
                {!!item.ctaText && !!item.ctaOnPress && <ButtonComponent style={[styles.button, item.ctaBig ? { width: '100%', height: 55 } : null]} label={item.ctaText} onPress={item.ctaOnPress} arrow arrowType={item.ctaArrowType} />}
              </View>
              <View>
              {config.consts.isScreenDesktop && item.character && <HeroCharacterComponent {...item.character} />}
              </View>
            </PaddedComponent>
          </LinearGradientComponent>
        </ImageBackground>
      </View>
    ) // NOTE: Lasciare lo style di ImageBackground vuoto in quanto da problemi su Android
  }

  return (
    <View>
      <SliderComponent items={props.slides || [{ loading: true }]} renderItem={renderSliderItem} />
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden'
  },
  image: {},
  gradient: {
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  content: {
    flexDirection: config.consts.isScreenMobile ? 'column' :'row',
    paddingVertical: config.consts.isScreenMobile ? 30 : 70,
    justifyContent: config.consts.isScreenMobile ? 'flex-end' : 'space-between',
    alignItems: 'flex-end',
    minHeight: config.consts.isScreenMobile ? 500 : 600
  },
  contentInner: {
    width: config.consts.isScreenMobile ? '100%' : '50%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  subtitle: {
    marginTop: config.consts.isScreenMobile ? 20 : 35,
    marginBottom: config.consts.isScreenMobile ? 20 : 35
  },
  text: {
    marginTop: config.consts.isScreenMobile ? 20 : 35,
  },
  button: {
    marginTop: 38
  },
  icons: {
    marginTop: 22,
    marginBottom: 22,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 45
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

HeroComponent.propTypes = {
  play: PropTypes.bool, // permette o meno l'esecuzione video
  slides: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    ctaText: PropTypes.string,
    ctaOnPress: PropTypes.func,
    ctaBig: PropTypes.bool,
    ctaArrowType: PropTypes.string,
    image: PropTypes.string,
    video: PropTypes.string,
    inner: PropTypes.shape({
      lenght: PropTypes.string,
      duration: PropTypes.string,
      vehicle: PropTypes.number
    })
  })),
  language: PropTypes.string,
}

HeroComponent.defaultProps = {
  slides: []
}
