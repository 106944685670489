import React, { useState, useEffect } from 'react'
import { setItem, getItem } from '../libs/storage'

export const AuthContext = React.createContext()

export function AuthProvider (props) {
  const [userData, setUserData] = useState({})
  const [userClient, setUserClient] = useState(null)
  const [userAccesstoken, setUserAccesstoken] = useState(null)
  const [userExpiry, setUserExpiry] = useState(null)
  const [authValid, setAuthValid] = useState(false)
  const [authLoaded, setAuthLoaded] = useState(false)

  // eseguo il controllo dei dati dell'utente al primo avvio
  useEffect(() => {
    const getAuthData = async () => {
      const authData = await getItem('authData')

      if (authData) {
        setUserData(authData.userData)
        setUserClient(authData.userClient)
        setUserAccesstoken(authData.userAccesstoken)
        setUserExpiry(authData.userExpiry)
        setAuthValid(authData.userClient && authData.userAccesstoken)
      }

      setAuthLoaded(true)
    }

    getAuthData()
  }, [])

  // funzione che gestisce i dati dell'utente di un'operazione di autenticazione
  const manageAuthData = async (data) => {
    const newUserData = {
      id: data.payload.data.id,
      uid: data.payload.data.uid,
      email: data.payload.data.email,
      username: data.payload.data.username
    }
    const newUserClient = data.client
    const newUserAccesstoken = data.accesstoken
    const newUserExpiry = data.expiry

    setUserData(newUserData)
    setUserClient(newUserClient)
    setUserAccesstoken(newUserAccesstoken)
    setUserExpiry(newUserExpiry)
    await setItem('authData', { userData: newUserData, userClient: newUserClient, userAccesstoken: newUserAccesstoken, userExpiry: newUserExpiry })
  }

  const manageUserDataUpdate = async (data) => {
    const newUserData = {
      id: userData.id,
      uid: userData.uid,
      email: data.payload.email,
      username: data.payload.username
    }

    setUserData(newUserData)
    await setItem('authData', { userData: newUserData, userClient, userAccesstoken, userExpiry })
  }

  return (
    <AuthContext.Provider value={{ authLoaded, authValid, userData, userClient, userAccesstoken, userExpiry, manageAuthData, manageUserDataUpdate }} >
      {props.children}
    </AuthContext.Provider>
  )
}
