import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import SliderComponent from './SliderComponent'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function BoxGridComponent (props) {

  const renderItem = (item, index) => {
    return (
      <View key={index} style={{ width: config.consts.isScreenMobile ? '100%' : '33.3333%', paddingHorizontal: 25, marginBottom: 50 }}>
        {props.renderItem(item.loading ? null : item)}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {(props.items || [{ loading: true }, { loading: true }, { loading: true }]).map((item, index) => renderItem(item, index))}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginHorizontal: -25,
    marginBottom: -50
  },
})

// Component props
//////////////////////////////////////////////////////////////////////////

BoxGridComponent.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
}