import React, { useContext } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { navigation } from '../libs/navigation'
import ImageComponent from './ImageComponent'
import PaddedComponent from './PaddedComponent'
import NavComponent from './NavComponent'
import TitleComponent from './TitleComponent'
import ButtonComponent from './ButtonComponent'
import { ArrowLeft, Menu } from './IconComponent'
import { UiContext } from '../contexts/UiContext'
import config from '../config'
import { openExternalLink } from '../libs/helpers'


// Component code
//////////////////////////////////////////////////////////////////////////

export default function HeaderComponent(props) {
  const { showHeader } = useContext(UiContext)
  if (!showHeader) return null

  if (config.consts.isWeb) return (
    <View style={styles.containerDesktop}>
      <PaddedComponent style={styles.content} horizontal>
        <ButtonComponent label={'Indietro'} onPress={() => window ? window.history.back() : openExternalLink('https://walkandlearn.net', true)} arrowLeft />
      </PaddedComponent>
    </View>
  )

  const current = navigation?.current?.getCurrentRoute()
  if (current?.name == 'RouteNavigator') {
    return null
  }

  const { scene, navigation: appNavigation } = props
  const { name, params } = scene.route
  const title = params?.screen_title || ''
  const isDashboard = name === 'Dashboard'


  const onOpenDrawer = () => {
    appNavigation.openDrawer()
  }

  const onGoToDashboard = () => {
    navigation.current.navigate('Dashboard')
  }

  const onGoBack = () => {
    navigation.current.goBack()
  }

  return (
    <View style={styles.container}>
      <PaddedComponent style={styles.content} horizontal>
        {/** LOGO SU WEB E HOMEPAGE */}
        {(config.consts.isWeb && isDashboard) && (
          <TouchableOpacity onPress={onGoToDashboard}>
            <ImageComponent height={config.consts.isScreenMobile ? 45 : 60} source={'logo'} />
          </TouchableOpacity>
        )}
        {/** LOGO SU WEB E NON HOMEPAGE */}
        {(config.consts.isWeb && !isDashboard) && (
          <TouchableOpacity onPress={onGoToDashboard}>
            <ImageComponent height={config.consts.isScreenMobile ? 45 : 60} source={'logo'} />
          </TouchableOpacity>
        )}
        {/** LOGO SU NATIVO E HOMEPAGE */}
        {(config.consts.isNative && isDashboard) && (
          <TouchableOpacity onPress={onGoToDashboard}>
            <ImageComponent height={45} source={'logo'} />
          </TouchableOpacity>
        )}
        {/** LOGO SU NATIVO E NON HOMEPAGE */}
        {(config.consts.isNative && !isDashboard) && (
          <TouchableOpacity style={styles.pageTitle} onPress={onGoBack}>
            <ArrowLeft style={styles.pageTitleIcon} width={20} height={20} /><TitleComponent style={styles.pageTitleLabel} label={title} labelSmall oneLine />
          </TouchableOpacity>
        )}
        {/** MENU SU WEB */}
        {(config.consts.isWeb && !config.consts.isScreenMobile) && (
          <View style={styles.navbar}>
            <NavComponent itemLabelStyle={styles.navbarItem} />
          </View>
        )}
        {/** MENU SU MOBILE */}
        {(config.consts.isScreenMobile) && (
          <TouchableOpacity style={styles.pageAction} onPress={onOpenDrawer}>
            <Menu style={styles.pageActionIcon} width={20} height={20} />
          </TouchableOpacity>
        )}
      </PaddedComponent>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    ...config.style.shadow,
    ...config.style.HeaderComponent.container,
    height: config.consts.isScreenMobile ? 70 : 100,
    justifyContent: 'center'
  },
  containerDesktop: {
    ...config.style.shadow,
    ...config.style.HeaderComponent.container,
    height: config.consts.isScreenMobile ? 70 : 100,
    justifyContent: 'center'
  },
  content: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: config.consts.isScreenMobile ? 20 : 36
  },
  navbar: {
    flexDirection: 'row'
  },
  navbarItem : { 
    marginLeft: 30, 
    fontSize: 16,
    letterSpacing: 2,
    textTransform: 'uppercase'
  },
  pageTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '75%'
  },
  pageTitleIcon: {
    marginRight: 15
  },
  pageTitleLabel: {
    // width: '100%'
  },
  pageAction: {

  },
  pageActionIcon: {
    
  },
})

// Component props
//////////////////////////////////////////////////////////////////////////

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}