import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import DashboardView from '../views/DashboardView'
import AreasView from '../views/AreasView'
import CharactersView from '../views/CharactersView'
import RoutesView from '../views/RoutesView'
import AreaView from '../views/AreaView'
import CharacterView from '../views/CharacterView'
import RouteView from '../views/RouteView'
import RouteNavigatorView from '../views/RouteNavigatorView'
import HeaderComponent from '../components/HeaderComponent'
import config from '../config'

export const MainStackNavigator = createStackNavigator()

export function MainNavigator (props) {
  return (
    <MainStackNavigator.Navigator
      screenOptions={{
        header: (props) => <HeaderComponent {...props} />,
        title: config.consts.name,
        animationEnabled: config.consts.isNative // NOTE: Attivare per animazioni su web
      }}
    >
      <MainStackNavigator.Screen name="Dashboard" component={DashboardView} />
      <MainStackNavigator.Screen name="Areas" component={AreasView} />
      <MainStackNavigator.Screen name="Characters" component={CharactersView} />
      <MainStackNavigator.Screen name="Routes" component={RoutesView} />
      <MainStackNavigator.Screen name="Area" component={AreaView} />
      <MainStackNavigator.Screen name="Character" component={CharacterView} />
      <MainStackNavigator.Screen name="Route" component={RouteView} />
      <MainStackNavigator.Screen name="RouteNavigator" component={RouteNavigatorView} options={{ headerShown: false }} />
    </MainStackNavigator.Navigator>
  )
}