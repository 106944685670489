export default {
  baseUrl: 'https://gestione.walkandlearn.net',
  // baseUrl: 'http://localhost:3001',

  areasIndex: {
    method: 'GET',
    path: '/areas/index-api',
    cached: true
  },
  areasShow: {
    method: 'GET',
    path: '/areas/show-api',
    cached: true
  },
  charactersIndex: {
    method: 'GET',
    path: '/characters/index-api',
    cached: true
  },
  charactersShow: {
    method: 'GET',
    path: '/characters/show-api',
    cached: true
  },
  routesIndex: {
    method: 'GET',
    path: '/routes/index-api2',
    cached: true
  },
  routesShow: {
    method: 'GET',
    path: '/routes/show-api2',
    cached: true
  }
}
