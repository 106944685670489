import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Modal, View } from 'react-native'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function ModalComponent (props) {
  return (
    <Modal
      style={styles.container}
      visible={props.visible}
      animationType='slide'
      statusBarTranslucent
      transparent
    >
      <View style={styles.overlay}>
        {props.children}
      </View>
    </Modal>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    borderWidth: 0
  },
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

ModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired
}

ModalComponent.defaultProps = {
  visible: false
}