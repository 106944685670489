import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, Text } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function TitleComponent (props) {

  // definisco lo stile della label in base alla dimensione scelta
  let styleLabelSize = stylesLabel.sizeNormal
  if (props.labelLarge) styleLabelSize = stylesLabel.sizeLarge
  if (props.labelSmall) styleLabelSize = stylesLabel.sizeSmall

  // definisco lo stile della label in base all'allineamento scelto
  let styleLabelAlign = stylesLabel.alignLeft
  if (props.labelCenter) styleLabelAlign = stylesLabel.alignCenter

  // definisco lo stile della label per il colore
  let styleLabelColor = {}
  if (props.color) styleLabelColor = { color: props.color }

  const labels = typeof props.label === 'object' ? props.label : [props.label]
  return (
    <View style={[styles.container, props.style]}>
      <Text style={[styles.label, styleLabelSize, styleLabelAlign, styleLabelColor]} numberOfLines={props.oneLine ? 1 : 0} ellipsizeMode='tail'>
        {labels.join('\n')}
      </Text>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {

  },
  label: {
    ...config.style.typography,
    ...config.style.typographyBold,
    color: config.colors.black,
  },
})

const stylesLabel = StyleSheet.create({
  // sizes
  sizeLarge: { fontSize: config.consts.isScreenMobile ? 35 : 40 },
  sizeNormal: { fontSize: config.consts.isScreenMobile ? 22 : 30 },
  sizeSmall: { fontSize: config.consts.isScreenMobile ? 16 : 24 },
  // align
  alignLeft: { textAlign: 'left' },
  alignCenter: { textAlign: 'center' }

})

// Component props
//////////////////////////////////////////////////////////////////////////

TitleComponent.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  style: PropTypes.any,
  labelLarge: PropTypes.bool,
  labelSmall: PropTypes.bool,
  labelCenter: PropTypes.bool,
  color: PropTypes.string,
  oneLine: PropTypes.bool
}

TitleComponent.defaultProps = {
  label: ''
}