import React from 'react'
import config from '../config'
import * as Video360ComponentProps from './Video360ComponentProps'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function Video360Component (props) {
  if (config.consts.isScreenMobile) {
    window.location.replace(props.source)
    return
  }

  return (
    <iframe src={props.source} style={styles.iframe} />
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = {
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

Video360Component.propTypes = Video360ComponentProps.propTypes

Video360Component.defaultProps = Video360ComponentProps.defaultProps