import React, { useEffect, useState, useContext } from 'react'
import { View, TouchableOpacity } from 'react-native'
import useApi from '../hooks/useApiHook'
import ContainerComponent from '../components/ContainerComponent'
import MapNavigatorComponent from '../components/MapNavigatorComponent'
import SwipeModalComponent from '../components/SwipeModalComponent'
import ButtonComponent from '../components/ButtonComponent'
import SpacerComponent from '../components/SpacerComponent'
import RouteStepContentComponent from '../components/RouteStepContentComponent'
import { MediaContext } from '../contexts/MediaContext'
import { ArrowLeft } from '../components/IconComponent'
import config from '../config'

export default function RouteNavigatorView(props) {
  const { route_permalink, data } = props.route.params
  const { mediaPlayer, resetMediaPlayer } = useContext(MediaContext)
  const [routesShowRequestStatus, routesShowRequestSend] = useApi('routesShow')
  const [watching, setWatching] = useState(false)
  const [starting, setStarting] = useState(true)
  const [route, setRoute] = useState(data)
  const [markers, setMarkers] = useState([])
  const [steps, setSteps] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  let isLast, isFirst
  if (currentIndex >= steps.length - 1) {
    isLast = true
    isFirst = false
  } else if (currentIndex <= 0) {
    isLast = false
    isFirst = true
  } else {
    isLast = false
    isFirst = false
  }

  useEffect(() => {
    loadData()
    resetMediaPlayer()
    return () => resetMediaPlayer()
  }, [])

  //////////////////////////////////////////////////////////////////////////

  const loadData = async () => {
    const responseRoute = await routesShowRequestSend({ route_permalink: route_permalink })
    const route = responseRoute.result ? responseRoute.payload.route : data
    const steps = responseRoute.result ? responseRoute.payload.route_steps.sort((a, b) => a.position - b.position) : []

    const markersData = steps.map((s) => ({
      geo_lat: s.geo_lat,
      geo_lng: s.geo_lng,
      position: s.position
    }))

    const stepsData = steps.map((s) => ({
      id: s.uuid,
      title: s.name,
      description: s.description,
      image: s.url_image_preview.original,
      files: s.files.sort((a, b) => a.position - b.position).map((file) => ({
        name: file.name,
        typology: file.typology,
        url: file.url,
        urls: file.urls,
        playerUrl: file.player_url
      }))
    }))

    setRoute(route)
    setMarkers(markersData)
    setSteps(stepsData)
  }

  const onChangeIndex = (stepIndex) => {
    setCurrentIndex(stepIndex)
  }

  const onGoToNext = () => {
    setWatching(false)
    if (!isLast) setCurrentIndex(currentIndex + 1)
  }

  const onGoToPrev = (watching) => {
    if (watching) {
      setWatching(false)
    } else {
      setWatching(true)
      if (!isFirst) setCurrentIndex(currentIndex - 1)
    }
  }

  const onEnd = () => {
    props.navigation.goBack()
  }

  // const onOpenNavigator = () => {
  //   if (config.consts.isNative) {
  //     // TODO
  //   } else {
  //     window.open(`https://maps.google.com/maps?q=loc:${markers[0].geo_lat},${markers[0].geo_lng}&navigate=yes`)
  //   }
  // }

  //////////////////////////////////////////////////////////////////////////

  let title = 'Hai scelto'
  let subtitle = route?.name
  if (watching) {
    title = steps[currentIndex].title
    subtitle = `Tappa ${currentIndex + 1} di ${steps.length}`
  } else if (!starting) {
    title = `Next stop`
    subtitle = steps[currentIndex].title
  }

  return (
    <ContainerComponent>
      {route && <MapNavigatorComponent vehicle={route.vehicle} markers={markers} currentMarker={currentIndex} onPressMarker={onChangeIndex} onPressClose={onEnd} onNearCurrentMarker={() => setWatching(true)} />}
      {!mediaPlayer.play && (
        <SwipeModalComponent title={title} subtitle={subtitle} secondary={!watching} visible={true}>
          {watching ? (
            <>
              <RouteStepContentComponent step={steps[currentIndex]} stepIndex={currentIndex} centered scrollableDescription />
              <SpacerComponent size={2} />
              {isLast ? (
                <ButtonComponent label='Chiudi' onPress={onEnd} />
              ) : (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <PrevButton onPress={() => onGoToPrev(true)} />
                  <ButtonComponent label='Prossima tappa' onPress={onGoToNext} arrow />
                </View>
              )}
            </>
          ) : (
            <>
              {starting ? (
                <>
                  <ButtonComponent label='Inizia il percorso' onPress={() => setStarting(false)} />
                  {/* <SpacerComponent size={1} /> */}
                  {/* <ButtonComponent label='Usa il navigatore' onPress={onOpenNavigator} secondary /> */}
                </>
              ) : (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {currentIndex > 0 && <PrevButton onPress={() => onGoToPrev(false)} />}
                  <ButtonComponent label='Salta il viaggio' onPress={() => setWatching(true)} secondary />
                </View>
              )}
            </>
          )}
        </SwipeModalComponent>
      )}
    </ContainerComponent>
  )
}

// Helpers
//////////////////////////////////////////////////////////////////////////

function PrevButton(props) {
  return <TouchableOpacity {...props} style={{ marginRight: 15, padding: 10 }}><ArrowLeft width={20} height={20} color={config.colors.black} /></TouchableOpacity>
}

