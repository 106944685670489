export function getMapDefaultStyle() {
  return 'mapbox://styles/mapbox/light-v10'
}

export function getMapCenter(coordinates, currentCoordinateIndex) {
  return coordinates && coordinates[currentCoordinateIndex] ? [coordinates[currentCoordinateIndex].geo_lng, coordinates[currentCoordinateIndex].geo_lat] : [12.492373, 41.890251]
}

export function getCoordinateArray(coordinate) {
  return coordinate ? [coordinate.geo_lng, coordinate.geo_lat] : [0, 0]
}

export function getCoordinatesGeoJsonLine(coordinates) {
  const geojson = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      properties: {},
      geometry: { type: 'LineString', coordinates: coordinates.map((c) => getCoordinateArray(c)) }
    }]
  }

  return geojson
}

export function getCoordinatesBound(coordinates) {
  let maxLng = null
  let maxLat = null
  let minLng = null
  let minLat = null

  coordinates.forEach((coordinate) => {
    if (!maxLng || maxLng < coordinate.geo_lng) {
      maxLng = coordinate.geo_lng
    }
    if (!maxLat || maxLat < coordinate.geo_lat) {
      maxLat = coordinate.geo_lat
    }
    if (!minLng || minLng > coordinate.geo_lng) {
      minLng = coordinate.geo_lng
    }
    if (!minLat || minLat > coordinate.geo_lat) {
      minLat = coordinate.geo_lat
    }
  })

  return {
    ne: [maxLng, maxLat],
    sw: [minLng, minLat]
  }
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}
