import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import ModalComponent from './ModalComponent'
import TitleComponent from './TitleComponent'
import PaddedComponent from './PaddedComponent'
import ButtonComponent from './ButtonComponent'
import SliderImagesComponent from './SliderImagesComponent'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MediaGalleryComponent (props) {
  return (
    <ModalComponent visible={props.play}>
      <View style={config.consts.isScreenMobile ? styles.containerMobile : styles.containerDesktop}>
        <PaddedComponent style={styles.header} container={false} horizontal vertical >
          {props.name && <TitleComponent style={styles.title} label={props.name} color={config.colors.black} oneLine labelSmall={config.consts.isScreenMobile} />}
          <ButtonComponent label={t('cta_close', props.language)} danger onPress={props.onClose} />
        </PaddedComponent>
        <SliderImagesComponent images={props.source} originalSize />
      </View>
    </ModalComponent>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  containerMobile: {
    width: '100%',
    height: '100%',
    backgroundColor: config.colors.black
  },
  containerDesktop: {
    width: '80%',
    height: '80%',
    backgroundColor: config.colors.black,
    ...config.style.blockSmall,
    ...config.style.shadow
  },
  header: {
    position: config.consts.isWeb ? 'absolute' : 'relative',
    top: 0,
    left: 0,
    width: '100%',
    paddingHorizontal: config.consts.isScreenMobile ? 20 : 80,
    backgroundColor: config.consts.isWeb ? 'rgba(0,0,0,0.5)' : config.colors.black,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: config.consts.isWeb ? 'auto' : 50,
    zIndex: 1
  },
  title: {
    width: '50%'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MediaGalleryComponent.propTypes = {
  source: PropTypes.array.isRequired,
  play: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  language: PropTypes.string,
}

MediaGalleryComponent.defaultProps = {
  onClose: () => {}
}