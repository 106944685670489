import { DefaultTheme } from '@react-navigation/native'
import consts from './consts'
import colors from './colors'

/**
 * Stile di base
 */
let baseStyle = {
  typography: {
    fontFamily: 'MontserratAlternates-Regular',
    color: colors.black
  },
  typographyBold: {
    fontFamily: 'MontserratAlternates-Bold',
  },
  shadow: {
    elevation: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 30
  },
  shadowWeb: 'rgb(68 68 68 / 30%) 0px 0px 30px',
  shadowSmall: {
    elevation: 2,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10
  },
  block: {
    borderRadius: consts.isScreenMobile ? 25 : 80,
    overflow: 'hidden'
  },
  blockSmall: {
    borderRadius: consts.isScreenMobile ? 25 : 25,
    overflow: 'hidden'
  },
  mediaHeader: {
    width: '100%',
    paddingHorizontal: consts.isScreenMobile ? 20 : 80,
    paddingVertical: consts.isScreenMobile ? 10 : 20,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: consts.isWeb ? 'auto' : 50,
    zIndex: 1
  }
}

/**
 * Stile singole componenti
 */
let componentsStyle = {
  Navigation: {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.primary,
      background: colors.background
    },
  },
  HeaderComponent: {
    container: {
      backgroundColor: colors.secondary
    }
  },
  FooterComponent: {
    container: {
      backgroundColor: colors.secondary
    }
  },
  ButtonComponent: {
    buttonPrimary: {
      backgroundColor: colors.primary
    },
    labelPrimary: {
      color: colors.white
    },
    buttonSecondary: {
      backgroundColor: colors.secondary
    },
    labelSecondary: {
      color: colors.black
    }
  },
  ButtonIconComponent: {
    border: {
      borderColor: colors.primary,
      backgroundColor: colors.primary
    }
  }
}

export default Object.assign({}, baseStyle, componentsStyle)
