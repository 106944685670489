import { func } from 'prop-types'
import React from 'react'
import { StyleSheet } from 'react-native'
import Svg, {
  Circle,
  Ellipse,
  G,
  Text,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from 'react-native-svg'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export function Example(props) {
  return null
}

export function WalkAndLearn(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 22.373 22" width={props.width} height={props.height} style={props.style || {}}>
        <Path data-name="Rettangolo 618" fill="none" d="M.303 0h22v22h-22z"/>
        <G data-name="Raggruppa 292" transform="translate(0 2.225)" fill={color}>
          <Path data-name="Tracciato 425" d="M22.171 1.871a.69.69 0 00-.832-.108l-6.93 4.025a.705.705 0 00-.238.231l-5.622 9.02L1.766 6.51l2.095-1a8.075 8.075 0 01-.224-1.419L.393 5.637A.689.689 0 00.15 6.686l7.92 9.959a.689.689 0 00.548.272.708.708 0 00.272-.057l.012-.005 6.9-3.13a.688.688 0 00.312-.284l6.171-10.743a.69.69 0 00-.114-.827zm-7.143 10.695l-4.623 2.1 4.844-7.772 4.559-2.648z"/>
          <Path data-name="Tracciato 426" d="M8.617 1.377a2.635 2.635 0 012.632 2.632c0 2.294-1.885 4.646-2.632 5.489-.747-.841-2.632-3.192-2.632-5.489a2.635 2.635 0 012.632-2.632m0-1.377a4.014 4.014 0 00-4.009 4.009c0 3.411 3.2 6.65 3.335 6.786a.952.952 0 001.347 0c.136-.136 3.335-3.375 3.335-6.786A4.014 4.014 0 008.617 0z"/>
          <Circle data-name="Ellisse 18" cx="1.201" cy="1.201" r="1.201" transform="translate(7.416 2.776)"/>
        </G>
      </Svg>
  )
}

export function Menu(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 16 12" width={props.width} height={props.height} style={props.style || {}}>
      <G transform="translate(.304 .778)" fill={color}>
        <Rect data-name="Rettangolo 551" width="16" height="2" rx="1" transform="translate(-.304 -.778)"/>
        <Rect data-name="Rettangolo 552" width="16" height="2" rx="1" transform="translate(-.304 4.222)"/>
        <Rect data-name="Rettangolo 607" width="16" height="2" rx="1" transform="translate(-.304 9.222)"/>
      </G>
    </Svg>
  )
}

export function Pause(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 7 10" width={props.width} height={props.height} style={props.style || {}}>
      <G fill={color}>
        <Path d="M0 0h2v10H0z"/>
        <Path d="M5 0h2v10H5z"/>
      </G>
    </Svg>
  )
}

export function Play(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 22 28" width={props.width} height={props.height} style={props.style || {}}>
      <Path fill={color} d="M0 0v28l22-14z"/>
    </Svg>
  )
}

export function Play2(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 110 110" width={props.width} height={props.height} style={props.style || {}}>
      <G>
        <G fill={color}>
          <Path d="M93.891 16.109a55 55 0 00-77.782 77.782 55.022 55.022 0 0067.35 8.183 4.3 4.3 0 00-4.453-7.35 46.818 46.818 0 1114.531-13.968 4.3 4.3 0 007.144 4.776 54.865 54.865 0 00-6.79-69.424z"/>
          <Path d="M50.416 32.532a9.683 9.683 0 00-14.534 8.4v28.136a9.658 9.658 0 0014.534 8.4L74.66 63.4a9.717 9.717 0 000-16.8zm19.931 23.434L46.103 70.038a1.023 1.023 0 01-1.073 0 1.063 1.063 0 01-.554-.968V40.931a1.063 1.063 0 01.554-.968 1.077 1.077 0 01.536-.151 1.057 1.057 0 01.537.153l24.244 14.073a1.123 1.123 0 010 1.932z"/>
        </G>
      </G>
    </Svg>
  )
}

export function ArrowRight(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 16 16" width={props.width} height={props.height} style={props.style || {}}>
      <Path fill={color} d="M8 0L6.545 1.455l5.506 5.506H0v2.078h12.052l-5.507 5.506L8 16l8-8z"/>
    </Svg>
  )
}

export function ArrowLeft(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 16 16" width={props.width} height={props.height} style={props.style || {}}>
      <Path fill={color} data-name="Tracciato 10" d="M8 16l1.455-1.455-5.506-5.506H16V6.961H3.948l5.507-5.506L8 0 0 8z"/>
    </Svg>
  )
}

export function ArrowUp(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 29.165 16.704" width={props.width} height={props.height} style={props.style || {}}>
      <Path d="M27.043 14.582L14.582 2.121 2.121 14.582" fill="none" stroke={color} strokeLinecap="round" strokeWidth="3"/>
    </Svg>
  )
}

export function ArrowDown(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 29.165 16.704" width={props.width} height={props.height} style={props.style || {}}>
      <Path d="M2.121 2.121l12.461 12.461L27.043 2.121" fill="none" stroke={color} strokeLinecap="round" strokeWidth="3"/>
    </Svg>
  )
}

export function MapMarker(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 45.515 59.686" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 286">
        <G data-name="Raggruppa 285">
          <Path data-name="Tracciato 607" d="M20.573 57.043C4.486 33.722 1.5 31.328 1.5 22.757a21.258 21.258 0 1142.515 0c0 8.571-2.986 10.964-19.073 34.285a2.658 2.658 0 01-4.369 0z" stroke={color} strokeWidth="3"/>
        </G>
      </G>
    </Svg>
  )
}

export function Audio(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 178">
        <G data-name="Ellisse 13" fill="none" stroke={color} strokeWidth="2">
          <Circle cx="30" cy="30" r="30" stroke="none"/>
          <Circle cx="30" cy="30" r="29"/>
        </G>
        <Path data-name="Tracciato 594" d="M26.352 20.753l-4.451 4.452h-5.1a1.2 1.2 0 00-1.2 1.2v7.2a1.2 1.2 0 001.2 1.2h5.1l4.448 4.447a1.2 1.2 0 002.048-.848V21.605a1.2 1.2 0 00-2.045-.852zm11.665-2.554a1.21 1.21 0 10-1.328 2.023 11.682 11.682 0 010 19.557 1.211 1.211 0 101.328 2.026 14.1 14.1 0 000-23.6zm1.584 11.806a9.231 9.231 0 00-4.288-7.812 1.2 1.2 0 00-1.656.373 1.214 1.214 0 00.37 1.668 6.833 6.833 0 010 11.541 1.214 1.214 0 00-.37 1.668 1.2 1.2 0 001.656.373 9.231 9.231 0 004.288-7.811zm-7.088-3.843a1.2 1.2 0 00-1.158 2.1 1.971 1.971 0 010 3.481 1.2 1.2 0 001.158 2.1 4.371 4.371 0 000-7.687z" fill={color}/>
      </G>
    </Svg>
  )
}

export function AudioOff(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 473">
        <G data-name="Raggruppa 178">
          <G data-name="Ellisse 13" fill="none" stroke={color} strokeWidth="2">
            <Circle cx="30" cy="30" r="30" stroke="none"/>
            <Circle cx="30" cy="30" r="29"/>
          </G>
          <Path data-name="Tracciato 594" d="M26.352 20.753l-4.451 4.452h-5.1a1.2 1.2 0 00-1.2 1.2v7.2a1.2 1.2 0 001.2 1.2h5.1l4.448 4.447a1.2 1.2 0 002.048-.848V21.605a1.2 1.2 0 00-2.045-.852zm11.665-2.554a1.21 1.21 0 10-1.328 2.023 11.682 11.682 0 010 19.557 1.211 1.211 0 101.328 2.026 14.1 14.1 0 000-23.6zm1.584 11.806a9.231 9.231 0 00-4.288-7.812 1.2 1.2 0 00-1.656.373 1.214 1.214 0 00.37 1.668 6.833 6.833 0 010 11.541 1.214 1.214 0 00-.37 1.668 1.2 1.2 0 001.656.373 9.231 9.231 0 004.288-7.811zm-7.088-3.843a1.2 1.2 0 00-1.158 2.1 1.971 1.971 0 010 3.481 1.2 1.2 0 001.158 2.1 4.371 4.371 0 000-7.687z" fill={color}/>
        </G>
        <Path data-name="Linea 36" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" d="M4 57.5l52-52"/>
      </G>
    </Svg>
  )
}

export function Time(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 50 50" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 286">
        <G data-name="Raggruppa 223">
          <G data-name="Ellisse 20" fill="none" stroke={props.color} strokeWidth="2">
            <Circle cx="25" cy="25" r="25" stroke="none"/>
            <Circle cx="25" cy="25" r="24"/>
          </G>
        </G>
        <G data-name="Raggruppa 230">
          <G data-name="Raggruppa 229" fill={props.color}>
            <Path data-name="Tracciato 604" d="M32.245 19.161l.871-.872.259.258a.615.615 0 10.868-.87l-1.3-1.3a.616.616 0 00-.87.871l.288.286-.857.857a9.553 9.553 0 00-5.556-2.5v-.8h.62a1.044 1.044 0 000-2.087h-3.131a1.044 1.044 0 100 2.087h.688v.8h-.039A9.553 9.553 0 0018.27 18.6l-.533.575a9.582 9.582 0 1014.508-.014zm-7.258 14.2a7.96 7.96 0 117.958-7.961 7.96 7.96 0 01-7.957 7.961z"/>
            <Path data-name="Tracciato 605" d="M25.619 24.689v-5.016l-.579-.928-.66.928v5.016a1.223 1.223 0 101.237 0z"/>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export function Distance(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 50 50" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 285">
        <G data-name="Raggruppa 223">
          <G data-name="Ellisse 20" fill="none" stroke={props.color} strokeWidth="2">
            <Circle cx="25" cy="25" r="25" stroke="none"/>
            <Circle cx="25" cy="25" r="24"/>
          </G>
        </G>
        <Path data-name="Tracciato 606" d="M30.474 16.5H18.848a4.312 4.312 0 00-4.34 4.407c.11 1.258.981 4.265 4.34 4.265h13.3c3.763 0 4.257 2.7 4.323 3.9s-.139 4.745-4.323 4.745H20.505" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="2"/>
      </G>
    </Svg>
  )
}


export function Bicycle(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 50 50" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 223">
        <G data-name="Ellisse 20" fill="none" stroke={color} strokeWidth="2">
          <Circle cx="25" cy="25" r="25" stroke="none"/>
          <Circle cx="25" cy="25" r="24"/>
        </G>
        <G data-name="Raggruppa 222">
          <G data-name="Raggruppa 221">
            <Path data-name="Tracciato 602" d="M34.805 21.496a6.39 6.39 0 00-2.306.428 18.131 18.131 0 01-1.288-3.791 4.227 4.227 0 00-4.139-3.4.922.922 0 100 1.844 2.379 2.379 0 012.086 1.241.919.919 0 00-.069.1l-.286.482-1.449 2.44H19.61l-.44-1.108h.18a.922.922 0 100-1.844h-3.177a.922.922 0 100 1.844h1.012l.8 2.025a6.423 6.423 0 104.539 7.083h1.694a.922.922 0 00.793-.451l4.846-8.159a24.59 24.59 0 001.024 2.6 6.419 6.419 0 103.92-1.338zm-18.632 7.341h4.485a4.578 4.578 0 110-1.844h-4.485a.922.922 0 000 1.844zm7.525-1.844h-1.169a6.415 6.415 0 00-1.982-3.776.924.924 0 00-.035-.11l-.169-.425h5.916zm11.107 5.5a4.576 4.576 0 01-3.084-7.959 40.833 40.833 0 002.325 3.9.922.922 0 001.518-1.048 40.321 40.321 0 01-2.253-3.8 4.578 4.578 0 111.494 8.905z" fill={color} />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export function Walk(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 50 50" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 223" stroke={color}>
        <G data-name="Ellisse 20" fill="none" strokeWidth="2">
          <Circle cx="25" cy="25" r="25" stroke="none"/>
          <Circle cx="25" cy="25" r="24"/>
        </G>
        <G data-name="Raggruppa 273" transform="translate(-3.473 6.966)" fill={color}>
          <Circle data-name="Ellisse 23" cx="2.5" cy="2.5" r="2.5" transform="translate(27.473 5.023)"/>
          <Path data-name="Tracciato 632" d="M36.151 16.847l-3.125-1.031s-1.729-4-1.79-4.126a2.44 2.44 0 00-2.124-1.274 2.346 2.346 0 00-1.031.243l-4.247 1.669a1.2 1.2 0 00-.667.667l-1.517 3.64a1.216 1.216 0 001.122 1.669 1.177 1.177 0 001.122-.758l1.244-3.155 1.274-.485-2.093 10.222-4.065 4.945a1.2 1.2 0 00.152 1.7 1.165 1.165 0 00.758.273 1.177 1.177 0 00.94-.455l4.247-5.157a1.154 1.154 0 00.243-.516l.728-3.519 3.278 2.365v6.067a1.214 1.214 0 002.427 0v-6.673a1.179 1.179 0 00-.485-.971L29.6 20.033l.819-4.1.576 1.335a1.313 1.313 0 00.728.667l3.64 1.213a1.537 1.537 0 00.394.061 1.242 1.242 0 001.153-.819 1.22 1.22 0 00-.759-1.543z"/>
        </G>
      </G>
    </Svg>
  )
}

export function Drive(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 50 50" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 223">
        <G data-name="Ellisse 20" fill="none" stroke={color} strokeWidth="2">
          <Circle cx="25" cy="25" r="25" stroke="none"/>
          <Circle cx="25" cy="25" r="24"/>
        </G>
        <Path data-name="Tracciato 643" d="M36.639 21.184h-2.855l-.794-1.985a5.316 5.316 0 00-4.961-3.358h-6.058a5.317 5.317 0 00-4.961 3.358l-.794 1.985H13.36a.572.572 0 00-.555.711l.286 1.146a.572.572 0 00.555.434h.958a3.032 3.032 0 00-1.053 2.29v2.29a3.028 3.028 0 00.763 2v2.579a1.527 1.527 0 001.527 1.527h1.526a1.527 1.527 0 001.527-1.528v-1.526h12.212v1.527a1.527 1.527 0 001.527 1.527h1.527a1.527 1.527 0 001.524-1.528v-2.579a3.026 3.026 0 00.763-2v-2.29a3.033 3.033 0 00-1.053-2.29h.957a.572.572 0 00.555-.434l.286-1.145a.573.573 0 00-.556-.711zm-16.8-.851a2.29 2.29 0 012.126-1.439h6.059a2.29 2.29 0 012.126 1.439l.951 2.377H18.894zm-2.472 7.708a1.522 1.522 0 110-3.044 2.937 2.937 0 012.29 2.283c0 .913-1.374.761-2.29.761zm15.266 0c-.916 0-2.29.152-2.29-.761a2.937 2.937 0 012.29-2.283 1.522 1.522 0 110 3.044z" fill={color} />
      </G>
    </Svg>
  )
}

export function CircleGallery(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
      <Circle cx="30" cy="30" r="30" fill="#fff"/>
      <Path d="M21.748 39.193a4.753 4.753 0 01-4.497-3.238l-.044-.146a4.63 4.63 0 01-.22-1.376v-8.655l-3.079 10.28a2.883 2.883 0 002.02 3.497l19.63 5.256c.244.064.49.094.73.094 1.265 0 2.42-.839 2.743-2.075l1.144-3.637H21.748zM25.238 25.864c1.4 0 2.54-1.138 2.54-2.538s-1.14-2.54-2.54-2.54a2.541 2.541 0 00-2.538 2.54c0 1.4 1.138 2.538 2.538 2.538z" fill={props.color} fillRule="nonzero"/>
      <G>
        <Path d="M41.106 16.979H22.065a3.178 3.178 0 00-3.174 3.173v13.963a3.178 3.178 0 003.174 3.174h19.04a3.178 3.178 0 003.174-3.174V20.152a3.178 3.178 0 00-3.173-3.173zm-19.041 2.538h19.04c.351 0 .635.285.635.635v9.011l-4.01-4.678c-.425-.5-1.04-.766-1.702-.781a2.22 2.22 0 00-1.696.8l-4.714 5.66-1.536-1.532a2.228 2.228 0 00-3.148 0l-3.504 3.502V20.152c0-.35.285-.635.635-.635z" fill={props.color} fillRule="nonzero"/>
      </G>
    </Svg>
  )
}

export function CircleVirtualTour(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 291" transform="translate(-260 -1805)">
        <Circle data-name="Ellisse 22" cx="30" cy="30" r="30" transform="translate(260 1805)" fill="#fff"  />
        <Path data-name="Tracciato 634" d="M289.934 1822.5a.59.59 0 01.273.066l9.415 4.8a.833.833 0 010 1.423l-9.4 4.849a.587.587 0 01-.272.067.575.575 0 01-.273-.066l-9.415-4.8a.833.833 0 010-1.423l9.394-4.849a.6.6 0 01.278-.067zm0 1.2l-8.565 4.421 8.583 4.374 8.565-4.421-8.583-4.374m10.146 6.4a.732.732 0 01.674.777l.022 11.363a.789.789 0 01-.405.715l-8.927 4.47a.618.618 0 01-.267.065.732.732 0 01-.674-.777l-.022-11.363a.789.789 0 01.405-.715l8.929-4.47a.6.6 0 01.266-.062zm-.334 1.371l-8.257 4.133.021 10.516 8.257-4.133-.021-10.516m-20.5 10.827l-.022-11.361a.687.687 0 01.937-.718l8.948 4.422a.788.788 0 01.409.713l.022 11.363a.686.686 0 01-.937.717l-8.948-4.421a.792.792 0 01-.41-.712z" fill={props.color} />
      </G>
    </Svg>
  )
}

export function CircleAudio(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G transform="translate(-260 -1805)">
        <Circle cx="30" cy="30" r="30" transform="translate(260 1805)" fill="#fff" />
        <G>
          <G>
            <Path d="M279.334 1843.016c-2.865-6.967-.935-13.624 4.074-17.075a11.524 11.524 0 0114.058.974 13.781 13.781 0 012.853 16.163c-.115-1.439-.281-2.874-.341-4.315-.315-7.716-4.131-12.505-10.065-12.585-6.055-.084-10.006 4.858-10.288 12.817-.044 1.348-.191 2.683-.291 4.021z" fill={props.color}/>
            <Path d="M285.624 1838.977v6.072c-1.385.743-2.876.76-3.353-.534a7.984 7.984 0 01-.121-4.6c.364-1.333 1.779-1.6 3.474-.938z" fill={props.color}/>
            <Path d="M294.01 1845.062v-5.823c1.336-1.119 2.8-.811 3.265.463a7.893 7.893 0 01.143 4.654c-.419 1.426-1.909 1.429-3.408.706z" fill={props.color}/>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export function CircleLink(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 292">
        <G data-name="Raggruppa 497" transform="translate(-260 -1805)">
          <Circle data-name="Ellisse 22" cx="30" cy="30" r="30" transform="translate(260 1805)" fill="#fff"/>
          <G data-name="iconfinder_link_hyperlink_5402394 (1)" fill={color}>
            <Path data-name="Tracciato 644" d="M298.33 1837.085a1.564 1.564 0 01-1.1-2.672l3.332-3.332a3.143 3.143 0 000-4.448l-2.232-2.231a3.238 3.238 0 00-4.448 0l-3.332 3.332a1.572 1.572 0 01-2.216-2.2l3.332-3.332a6.444 6.444 0 018.9 0l2.216 2.216a6.287 6.287 0 010 8.9l-3.348 3.316a1.572 1.572 0 01-1.104.451zm-14.448 12.495a6.287 6.287 0 01-4.448-1.839l-2.211-2.216a6.287 6.287 0 010-8.9l3.348-3.332a1.572 1.572 0 012.2 2.216l-3.332 3.336a3.143 3.143 0 000 4.448l2.232 2.232a3.238 3.238 0 004.448 0l3.332-3.332a1.572 1.572 0 112.216 2.2l-3.332 3.332a6.287 6.287 0 01-4.453 1.855z"/>
            <Path data-name="Tracciato 645" d="M285.002 1841.533a1.572 1.572 0 01-1.116-2.687l10.011-10a1.573 1.573 0 112.216 2.232l-10.006 9.996a1.572 1.572 0 01-1.105.459z"/>
          </G>
        </G>
    </G>
    </Svg>
  )
}

export function CircleVideo(props) {
  const color = props.color || config.colors.black
  return (
    <Svg viewBox="0 0 60 60" width={props.width} height={props.height} style={props.style || {}}>
      <G data-name="Raggruppa 293" transform="translate(-260 -1805)">
        <Circle data-name="Ellisse 22" cx="30" cy="30" r="30" transform="translate(260 1805)" fill="#fff" />
        <G data-name="Raggruppa 292">
          <Path data-name="Tracciato 454" d="M300.366 1829.264l-3.3 3.682v-4.196a3.1 3.1 0 00-2.957-3.22h-13.654a3.1 3.1 0 00-2.955 3.22v12.5a3.1 3.1 0 002.955 3.219h13.654a3.1 3.1 0 002.957-3.219v-4.247l3.3 3.714a1.174 1.174 0 001.361.313 1.361 1.361 0 00.773-1.255v-9.559a1.361 1.361 0 00-.772-1.26 1.172 1.172 0 00-1.362.308zm-9.966 6.546l-5.287 3.82c-.711.514-1.288.093-1.288-.94v-7.617c0-1.032.577-1.453 1.288-.939l5.287 3.816a1.075 1.075 0 010 1.866z" fill={props.color} />
        </G>
      </G>
    </Svg>
  )
}


// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({})

