import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function SpacerComponent (props) {

  // imposto la dimensione dello spacer moltiplicando size per l'unità base
  const size = props.size * 10

  return (
    <View style={{
      width: props.horizontal ? size : 0,
      height: props.horizontal ? 0 : size
    }} { ...props } />
  )
}

// Component props
//////////////////////////////////////////////////////////////////////////

SpacerComponent.propTypes = {
  horizontal: PropTypes.bool,
  size: PropTypes.number
}

SpacerComponent.defaultProps = {
  horizontal: false,
  size: 1
}