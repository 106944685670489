import React from 'react'
import { Linking, Text, TouchableOpacity } from 'react-native'
import config from "../config"

/**
 * @function htmlTextToRnText
 * @param {*} text 
 * @returns 
 */
export function htmlTextToRnText(text, onPress = () => {}) {
  var lookup = {
    'b': config.style.typographyBold,
    'i': { fontStyle: 'italic' },
    'a': { textDecoration: 'underline' }
  }

  try {
    // split on highlight tags to get the arrays.
    text = text.split(/(<.*?>.*?<\/.*?>)/g);

    // odd indexes in array will be tags to be replaced with text tag.
    for(var i = 1; i < text.length; i += 2) {

      // extract the text string to highlight.
      var word = text[i].replace(/<.*?>(.*?)<\/.*?>/, '$1');
      var tag = text[i][1];
      var meta = text[i][2];

      if (tag == 'a') {
        // apply the style and return the text tag.
        text[i] = <TouchableOpacity onPress={() => onPress()} key={i}><Text style={lookup[tag]}>{word}</Text></TouchableOpacity>;
      } else {
        // apply the style and return the text tag.
        text[i] = <Text key={i} style={lookup[tag]}>{word}</Text>;
      }
    }
    return text;
  } catch (e) {
    return <Text>{text}</Text>
  }
}

/**
 * @function colorShade
 * Permette di modificare (schiarire/scurire) un colore esadecimale.
 * Il secondo parametro definisce come comportarsi.
 * Un valore negativo indica che bisogna scurire il colore; uno positivo indica che bisogna chiarirlo.
 * @param {*} col Colore
 * @param {*} amt Valore di cambio dello shade
 */
export function colorShade(col, amt) {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}

/**
 * @function randomString
 * Genera una stringa randomica di lunghezza definita tramite parametro.
 * @param {*} length 
 */
export function randomString(length = 8) {
  return Math.random().toString(16).substr(2, length)
}
/**
 * @function truncateString
 * Tronca il testo e aggiunge dei ...
 * @param {*} str 
 * @param {*} num 
 */
export function truncateString(str, length, delimiter = '...') {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= length) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.substring(0, (str.indexOf(' ', str.substring(length)) + length)) + delimiter;
}

/**
 * @function debounce
 * Ritarda l'esecuzione di una funzione per evitare multiple esecuzioni
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function debounce(fn, delay) {
  var timer = null;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * @function openExternalLink
 * Apre un link esterno all'app.
 * @param {*} link 
 */
export function openExternalLink(link, internal = false) {
  if (config.consts.isWeb) {
    if (internal) {
      window.location.replace(link)
    } else {
      window.open(link, '_blank')
    }
  } else {
    Linking.openURL(link)
  }
}

/**
 * @function secondsToTimeString
 * @param {*} seconds 
 */
export function secondsToTimeString(seconds, forceMinutes = false, forceHours = false) {
  var sec_num = parseInt(seconds, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  
  if (hours != '00' || forceHours) {
    return hours+':'+minutes+':'+seconds;
  } else if (minutes != '00' || forceMinutes) {
    return minutes+':'+seconds;
  } else {
    return seconds;
  }
}