import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, Text } from 'react-native'
import { htmlTextToRnText } from '../libs/helpers'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function TextComponent (props) {

  // definisco lo stile della label in base alla dimensione scelta
  let styleLabelSize = stylesLabel.sizeNormal
  if (props.labelSmall) styleLabelSize = stylesLabel.sizeSmall

  // definisco lo stile della label in base all'allineamento scelto
  let styleLabelAlign = stylesLabel.alignLeft
  if (props.labelCenter) styleLabelAlign = stylesLabel.alignCenter

  // definisco lo stile della label per il weight
  let styleLabelWeight = stylesLabel.weightNormal
  if (props.labelBold) styleLabelWeight = stylesLabel.weightBold

  // definisco lo stile della label per il colore
  let styleLabelColor = {}
  if (props.color) styleLabelColor = { color: props.color }

  let labels = typeof props.label === 'object' ? props.label : [props.label]
  if (!labels) labels = []
  return (
    <View style={[styles.container, props.style]}>
      <Text style={[styles.label, styleLabelAlign, styleLabelSize, styleLabelWeight, styleLabelColor, props.styleText]}>
        {htmlTextToRnText(labels.join('\n'), props.onLabelPress)}
      </Text>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {},
  label: {
    ...config.style.typography,
    color: config.colors.black,
    ...config.style.TextComponentLabel,
  },
})

const stylesLabel = StyleSheet.create({
  // sizes
  sizeNormal: {
    fontSize: config.consts.isScreenMobile ? 14 : 20,
    lineHeight: config.consts.isScreenMobile ? 20 : 30
  },
  sizeSmall: { fontSize: config.consts.isScreenMobile ? 10 : 14 },
  // align
  alignLeft: { textAlign: 'left' },
  alignCenter: { textAlign: 'center' },
  // weight
  weightNormal: {},
  weightBold: {
    ...config.style.typographyBold,
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

TextComponent.propTypes = {
  label: PropTypes.any,
  style: PropTypes.any,
  labelCenter: PropTypes.bool,
  labelSmall: PropTypes.bool,
  labelBold: PropTypes.bool,
  color: PropTypes.string,
  styleText: PropTypes.any,
  onLabelPress: PropTypes.func
}

TextComponent.defaultProps = {
  label: '',
  onLabelPress: () => {}
}