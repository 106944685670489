import React, { useState, useEffect } from 'react'
import { useIsFocused } from '@react-navigation/native'
import useApi from '../hooks/useApiHook'
import { paramsGenerator, titleGenerator } from '../libs/navigation'
import ContainerComponent from '../components/ContainerComponent'
import PaddedComponent from '../components/PaddedComponent'
import HeroComponent from '../components/HeroComponent'
import BoxComponent from '../components/BoxComponent'
import BoxGridComponent from '../components/BoxGridComponent'
import SpacerComponent from '../components/SpacerComponent'
import CardComponent from '../components/CardComponent'
import FooterComponent from '../components/FooterComponent'
import config from '../config'

export default function AreaView(props) {
  const { area_permalink, data } = props.route.params
  const [hero, setHero] = useState(null)
  const [area, setArea] = useState(null)
  const [routes, setRoutes] = useState(null)
  const [_areasShowRequestStatus, areasShowRequestSend] = useApi('areasShow')
  const [_routesIndexRequestStatus, routesIndexRequestSend] = useApi('routesIndex')
  const isFocused = useIsFocused()

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    loadData()
  }, [])

  //////////////////////////////////////////////////////////////////////////

  const loadData = async () => {
    (data ? new Promise((r) => r()) : areasShowRequestSend({ area_permalink: area_permalink })).then((response) => {
      const area = data ? data : (response.result ? response.payload.area : {})

      routesIndexRequestSend({ area_uuid: area.uuid }).then((response) => {
        const routes = response.result ? response.payload.data : []

        setHero([{
          id: area.uuid,
          title: area.name,
          subtitle: area.place,
          text: area.description,
          textComplete: true,
          image: area.url_image_preview.desktop
        }])
  
        setArea(area)
        setRoutes(routes)
  
        props.navigation.setOptions({ title: titleGenerator(area.name) })
      })
    })
  }

  const onGoToRoute = (data) => {
    props.navigation.push('Route', paramsGenerator({ route_permalink: data.permalink, native: { data, screen_title: data.name } }))
  }

  const onGoBack = () => {
    props.navigation.goBack()
  }

  //////////////////////////////////////////////////////////////////////////

  const renderCardItem = (item) => {
    return <CardComponent loading={!item} title={item?.name} image={item?.url_image_preview?.mobile} onPress={() => onGoToRoute(item)} />
  }

  return (
    <ContainerComponent scrollable >
      <HeroComponent slides={hero} onPressBack={props.navigation.canGoBack() ? onGoBack : null} />
      <SpacerComponent size={5} />
      <PaddedComponent horizontal>
        <BoxComponent title={'Percorsi'}>
          <BoxGridComponent items={routes} renderItem={(item) => renderCardItem(item)} />
        </BoxComponent>
      </PaddedComponent>
      <SpacerComponent size={5} />

      <FooterComponent />
    </ContainerComponent>
  )
}