import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native'
import { CircleVirtualTour, CircleAudio, CircleVideo, CircleLink, CircleGallery } from './IconComponent'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function ButtonIconComponent (props) {

  const iconSize = config.consts.isScreenMobile ? 40 : 60

  return (
    <TouchableOpacity style={[styles.container, props.style]} onPress={props.onPress}>
      <View style={[styles.border]}>
        <View style={styles.content}>
          {props.icon === 'gallery' && <CircleGallery width={iconSize} height={iconSize} color={config.colors.primary} />}
          {props.icon === 'link' && <CircleLink width={iconSize} height={iconSize} color={config.colors.primary} />}
          {props.icon === 'audio' && <CircleAudio width={iconSize} height={iconSize} color={config.colors.primary} />}
          {props.icon === 'video' && <CircleVideo width={iconSize} height={iconSize} color={config.colors.primary} />}
          {props.icon === 'virtual_tour' && <CircleVirtualTour width={iconSize} height={iconSize} color={config.colors.primary} />}
          <Text style={styles.label}>{props.label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}


// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: 'auto',
    borderRadius: 40,
    ...config.style.shadowSmall
  },
  border : {
    ...config.style.ButtonIconComponent.border,
    borderRadius: 40,
    borderWidth: 3
  },
  content: {
    borderRadius: 40,
    borderColor: config.colors.primary,
    borderWidth: 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  label: {
    ...config.style.typography,
    ...config.style.typographyBold,
    fontSize: 12,
    textTransform: 'uppercase',
    textAlign: 'center',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 15,
    marginRight: 15,
    color: config.colors.white
  }
})
// Component props
//////////////////////////////////////////////////////////////////////////

ButtonIconComponent.propTypes = {
  icon: PropTypes.oneOf(['audio', 'video', 'virtual_tour', 'gallery', 'link']).isRequired,
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.any
}

ButtonIconComponent.defaultProps = {
  icon: '',
  onPress: () => {}
}