import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, TouchableOpacity } from 'react-native'
import TextComponent from './TextComponent'
import { navigation, paramsGenerator } from '../libs/navigation'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function NavComponent (props) {

  const onGoToPage = (pageName, internalScreen, internalScreenTitle = '') => {
    navigation.current.navigate(pageName, { screen: internalScreen, params: paramsGenerator({ native: { screen_title: internalScreenTitle } }) })
  }

  return (
    <>
      <TouchableOpacity style={props.itemStyle} onPress={() => onGoToPage('MainNavigator', 'Dashboard')}>
        <TextComponent label="Home" styleText={props.itemLabelStyle} labelBold labelCenter />
      </TouchableOpacity>
      <TouchableOpacity style={props.itemStyle} onPress={() => onGoToPage('MainNavigator', 'Areas', 'Aree')}>
        <TextComponent label="Aree" styleText={props.itemLabelStyle} labelBold labelCenter />
      </TouchableOpacity>
      <TouchableOpacity style={props.itemStyle} onPress={() => onGoToPage('MainNavigator', 'Characters', 'Personaggi')}>
        <TextComponent label="Personaggi" styleText={props.itemLabelStyle} labelBold labelCenter />
      </TouchableOpacity>
      <TouchableOpacity style={props.itemStyle} onPress={() => onGoToPage('MainNavigator', 'Routes', 'Percorsi')}>
        <TextComponent label="Percorsi" styleText={props.itemLabelStyle} labelBold labelCenter />
      </TouchableOpacity>
      {config.consts.isNative && (
        <TouchableOpacity style={props.itemStyle} onPress={() => onGoToPage('TutorialNavigator')} >
          <TextComponent label="Tutorial" styleText={props.itemLabelStyle} labelBold labelCenter />
        </TouchableOpacity>
      )}
      {/* <TouchableOpacity style={props.itemStyle} onPress={() => onGoToUrl('https://walkandlearn.net')}>
        <TextComponent label="Il progetto" styleText={props.itemLabelStyle} labelBold labelCenter />
      </TouchableOpacity> */}
    </>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({})

// Component props
//////////////////////////////////////////////////////////////////////////

NavComponent.propTypes = {
  itemStyle: PropTypes.any,
  itemLabelStyle: PropTypes.any
}

NavComponent.defaultProps = {
  itemStyle: {},
  itemLabelStyle: {}
}