import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function LoadingComponent (props) {

  let styleContainerExtra = {}
  if (props.full) styleContainerExtra = styles.containerFull

  return (
    <View style={[styles.container, styleContainerExtra]}>
      <ActivityIndicator size={props.size} color={props.inverse ? config.colors.white : config.colors.primary} />
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  containerFull: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

LoadingComponent.propTypes = {
  full: PropTypes.bool,
  inverse: PropTypes.bool,
  size: PropTypes.any
}

LoadingComponent.defaultProps = {
  size: 30
}