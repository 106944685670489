import React, { useContext, useState, useEffect } from 'react'
import { StatusBar, SafeAreaView } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { AuthProvider, AuthContext } from './contexts/AuthContext'
import { LocalesProvider, LocalesContext } from './contexts/LocalesContext'
import { TutorialProvider, TutorialContext } from './contexts/TutorialContext'
import { UserProvider, UserContext } from './contexts/UserContext'
import { MediaProvider } from './contexts/MediaContext'
import { UiProvider } from './contexts/UiContext'
import { AppNavigator } from './navigators/AppNavigator'
import MediaManagerComponent from './components/MediaManagerComponent'
import LoadingComponent from './components/LoadingComponent'
import { navigation, linking } from './libs/navigation'
import { reset } from './libs/storage'
import config from './config'

function App() {
  const { localesLoaded } = useContext(LocalesContext)
  const { tutorialLoaded } = useContext(TutorialContext)
  const { authLoaded } = useContext(AuthContext)
  const { userLoaded } = useContext(UserContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => { if (config.consts.runAsFirstTime) reset() }, [])

  // mantengo sotto controllo gli aspetti di interesse per considerare l'app caricata
  useEffect(() => {
    if (localesLoaded && tutorialLoaded && authLoaded && userLoaded) {
      setLoading(false)
    }
  }, [localesLoaded, tutorialLoaded, authLoaded, userLoaded])

  // render in attesa del caricamento
  if (loading) return <LoadingComponent full />

  // render finale
  return (
    <NavigationContainer theme={config.style.Navigation} linking={linking} ref={navigation}>
      <StatusBar barStyle={'dark-content'} backgroundColor={config.colors.secondary} />
      <AppNavigator />
      <MediaManagerComponent />
    </NavigationContainer>
  )
}

export default () => {
  return (
    <UiProvider>
      <LocalesProvider>
        <TutorialProvider>
          <AuthProvider>
            <MediaProvider>
              <UserProvider>
                <SafeAreaView style={{ flex: 1, backgroundColor: config.colors.white }}>
                  <App />
                </SafeAreaView>
              </UserProvider>
            </MediaProvider>
          </AuthProvider>
        </TutorialProvider>
      </LocalesProvider>
    </UiProvider>
  )
}
