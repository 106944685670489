import { createRef } from 'react'
import config from '../config'

/**
 * Ritorna la ref all'navigator principale (quello in uso)
 */
export const navigation = createRef()

/**
 * Esegue la mappatura delle view del navigator con gli url per il web.
 * https://reactnavigation.org/docs/configuring-links
 */
export const linking = {
  prefixes: ['http://localhost:3000'],
  config: {
    screens: {
      MainNavigator: {
        screens: {
          Dashboard: '/',
          Routes: '/percorsi',
          Areas: '/aree',
          Characters: '/personaggi',
          Area: '/aree/:area_permalink',
          Character: '/personaggi/:character_permalink',
          Route: '/percorsi/:route_permalink',
          RouteNavigator: '/percorsi/navigatore/:route_permalink'
        }
      },
      TutorialNavigator: {
        screens: {
          Tutorial: '/tutorial'
        }
      },
    },
  },
}

/**
 * Genera i parametri per il navigator filtrando quelli solo per l'app native e
 * quelli solo per l'app web in modo da evitare dati inutili tra i due supporti.
 * Esempio:
 * - INPUT { campo_a: 1, native: { campo_b: 2 }, web: { campo_c: 3 } }
 * - OUTPUT NATIVE: { campo_a: 1, campo_b: 2 }
 * - OUTPUT WEB: { campo_a: 1, campo_c: 3 }
 * @param {*} params 
 */
export const paramsGenerator = (params) => {
  let finalParams = Object.assign({}, params)

  if (config.consts.isNative && params.native) {
    finalParams = Object.assign(finalParams, params.native)
  }
  if (config.consts.isWeb && params.web) {
    finalParams = Object.assign(finalParams, params.web)
  }

  delete finalParams.native
  delete finalParams.web

  return finalParams
}

/**
 * Genera una string da utilizzare come titolo della schermata.
 */
export const titleGenerator = (title = null) => {
  if (!title) return config.consts.name

  if (config.consts.isNative) {
    return title
  }
  if (config.consts.isWeb) {
    return `${title} - ${config.consts.name}`
  }

  return config.consts.name
}