import PropTypes from 'prop-types'

export const propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string, // titolo della traccia
  tracks: PropTypes.array,
  onTrackChange: PropTypes.func,
  onTrackPause: PropTypes.func,
  onTrackPlay: PropTypes.func,

  // props di gestione audio
  play: PropTypes.bool, // gestisce lo stato del player
  mute: PropTypes.bool, // gestisce lo stato dell'audio
}

export const defaultProps = {
  onTrackChange: () => {},
  onTrackPause: () => {},
  onTrackPlay: () => {}
}
