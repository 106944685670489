import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ImageBackground } from 'react-native'
import { MediaContext } from '../contexts/MediaContext'
import SliderComponent from './SliderComponent'
import LinearGradientComponent from './LinearGradientComponent'
import ButtonComponent from './ButtonComponent'
import TitleComponent from './TitleComponent'
import TextComponent from './TextComponent'
import LoadingComponent from './LoadingComponent'
import RouteStepContentComponent from './RouteStepContentComponent'
import config from '../config'
import { t } from '../libs/locales'


// Component code
//////////////////////////////////////////////////////////////////////////

export default function RouteStepsSliderComponent(props) {
  const { setMediaPlayer, toggleMediaPlayer, mediaPlayer } = useContext(MediaContext)

  // Memorizzo la mappatura delle tracce audio per la gestione del player audio
  const audioTracks = useMemo(() => {
    if (!props.steps) return {}

    return props.steps.map((step, index) => {
      const audios = step.files.filter((f) => f.typology === 1)
      return audios
    })
  }, [props.steps])

  // Eseguo forzatura di esecuzione player 
  useEffect(() => {
    if (props.steps && props.autoplay && !mediaPlayer.play) {
      if (props.steps) manageItemAudio(null, props.currentStep)
    }
  }, [props.steps, props.autoplay])

  /////////////////////////////////////////////////////////////////////////////////

  const manageItemAudio = (file = null, itemIndex = 0) => {
    if (!file && audioTracks && audioTracks[itemIndex]) file = audioTracks[itemIndex][0]
    if (!file) return

    const step = props.steps[itemIndex]

    setMediaPlayer({ isInternal: props.isInternal, label: step.label, language: props.language, url: file.url, playerUrl: file.playerUrl }, 'audio', { name: file.name, stepsTracks: audioTracks, onStepsTrackChange: onChangeItem })
    toggleMediaPlayer(true)
  }

  const onChangeItem = (itemIndex, itemAudioIndex = null) => {
    props.onChangeStep(itemIndex)
    if (props.autoplay || mediaPlayer.play) manageItemAudio(itemAudioIndex, itemIndex)
  }

  /////////////////////////////////////////////////////////////////////////////////

  const renderItem = (item, itemIndex) => {
    const style = [styles.slide, config.consts.isNative ? styles.block : null]

    // renderizzo versione temporanea se sono in modalità di caricamento
    if (item.loading) {
      return (
        <View key={itemIndex} style={style.concat([styles.slideLoading])}>
          <LoadingComponent full />
        </View>
      )
    }

    const hasNext = itemIndex < props.steps.length - 1

    return (
      <View style={style} key={item.id}>
        <ImageBackground source={{ uri: item.image }} resizeMode={'cover'} style={styles.slideImage}>
          <LinearGradientComponent colors={['rgba(0, 0, 0, 0)','rgba(0, 0, 0, 0.5)']} style={styles.slideGradient}>
            <View style={[styles.slidePadding, styles.slideHeader]} vertical horizontal>
              <View style={styles.slideHeaderHead}>
                {/* {hasNext && <ButtonComponent label={t('cta_next', props.language)} arrow secondary onPress={() => onChangeItem(itemIndex + 1)} />} */}
                <View style={styles.slideHeaderBall}>
                  <TextComponent label={(itemIndex + 1).toString()} labelBold labelCenter color={config.colors.primary} styleText={{fontSize: 20 }} />
                </View>
                {hasNext && <ButtonComponent arrow rounded onPress={() => onChangeItem(itemIndex + 1)} />}
              </View>
              <View>
                <TextComponent label={item.label || `${t('label_route_step', props.language).toUpperCase()} ${itemIndex + 1}`} color={config.colors.white} styleText={{ letterSpacing: 2 }} labelSmall />
                <TitleComponent label={item.title} style={styles.slideHeaderTitle} color={config.colors.secondary} labelLarge={config.consts.isScreenDesktop} />
              </View>
            </View>
          </LinearGradientComponent>
        </ImageBackground>
        <View style={[styles.slidePadding, styles.slideContent]}>
          <RouteStepContentComponent isInternal={props.isInternal} step={item} stepIndex={itemIndex} customAudioManager={(file) => manageItemAudio(file, itemIndex)} language={props.language} />
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.container, props.underMap ? styles.containerUnderMap : null]}>
      <SliderComponent
        items={props.steps || [{ loading: true }]}
        renderItem={renderItem}
        itemsToShow={1}
        onChangeItem={onChangeItem}
        currentItem={props.currentStep}
        dotsNumber
        dots={config.consts.isWeb}
        dotsTop={props.isInternal}
        fade
        dotsTheme='dark'
        block
        adaptive
        ctaNext={t('cta_next_route', props.language)}
      />
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {

  },
  containerUnderMap: {
    position: 'relative',
    top: config.consts.isScreenMobile ? 0 : -100,
    width: config.consts.isScreenMobile ? '100%' : '90%',
    margin: 'auto'
  },
  slide: {
    width: '100%',
    height: config.consts.isWeb ? '100%' : 'auto',
    backgroundColor: config.colors.secondary
  },
  block: {
    ...config.style.block,
  },
  slideLoading: {
    height: 400,
    width: '100%'
  },
  slideImage: {
    
  },
  slideGradient: {

  },
  slidePadding: {
    paddingVertical: config.consts.isScreenMobile ? 15 : 60,
    paddingHorizontal: config.consts.isScreenMobile ? 15 : 60
  },
  slideHeader: {
    height: config.consts.isScreenMobile ? 200 : 400,
    justifyContent: 'space-between'
  },
  slideHeaderHead: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between' 
  },
  slideHeaderBall: {
    width: 47,
    height: 47,
    borderRadius: 47,
    backgroundColor: config.colors.white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slideHeaderTitle: {

  },
  slideContent: {
    paddingVertical: config.consts.isScreenMobile ? 15 : 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: config.consts.isWeb ? (config.consts.isScreenMobile ? 'calc(100% - 200px)' : 'calc(100% - 350px)') : 'auto',
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

RouteStepsSliderComponent.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onChangeStep: PropTypes.func,
  autoplay: PropTypes.bool,
  underMap: PropTypes.bool,
  language: PropTypes.string // lingua da utilizzare nelle traduzioni (per forzare al posto di quella di sistema)
}

RouteStepsSliderComponent.defaultProps = {
  onChangeStep: () => {},
  underMap: false,
  autoplay: false
}