import React, { useContext } from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { MainNavigator } from './MainNavigator'
import { TutorialNavigator } from './TutorialNavigator'
import { TutorialContext } from '../contexts/TutorialContext'
import DrawerComponent from '../components/DrawerComponent'
import config from '../config'

export const AppDrawerNavigator = createDrawerNavigator()

export function AppNavigator (props) {
  const { tutorialActive } = useContext(TutorialContext)

  // identifico il navigator corretto in base allo stato dei vari context
  let initialRoute = 'MainNavigator'
  if (config.consts.isNative && tutorialActive) {
    initialRoute = 'TutorialNavigator'
  }

  return (
    <AppDrawerNavigator.Navigator
      initialRouteName={initialRoute}
      screenOptions={{ headerShown: false }}
      drawerContent={(props) => <DrawerComponent {...props} />}
    >
      <AppDrawerNavigator.Screen name="MainNavigator" component={MainNavigator} options={{ title: 'Home' }} />
      <AppDrawerNavigator.Screen name="TutorialNavigator" component={TutorialNavigator} options={{ title: 'Tutorial' }} />
    </AppDrawerNavigator.Navigator>
  )
}