import PropTypes from 'prop-types'

export const propTypes = {
  markers: PropTypes.array,
  currentMarker: PropTypes.number,
  onPressMarker: PropTypes.func,
  timeout: PropTypes.number // timeout di ritardo per il render -> utile per velocizzare il caricamento della view dato che la mappa è pesante!!
}

export const defaultProps = {
  markers: [],
  currentMarker: 0,
  onPressMarker: () => {}
}
