import PropTypes from 'prop-types'
import config from '../config'

export const propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  onChangeItem: PropTypes.func,
  currentItem: PropTypes.number,
  itemsToShow: PropTypes.number,
  autoplay: PropTypes.bool,
  adaptive: PropTypes.bool,
  arrows: PropTypes.bool, // specifica se mostrare le frecce di navigazione (solo per web su box slider)
  infinity: PropTypes.bool, // specifica se mostrare lo slider infinito o meno (solo per web)
  block: PropTypes.bool, // specifica se applicare lo stile block
  dots: PropTypes.bool, // specifica se visualizzare la paginazione
  dotsTop: PropTypes.bool, // specifica se visualizzare la paginazione in alto
  dotsNumber: PropTypes.bool, // specifica se visualizzare la paginaziona a numeri
  dotsInside: PropTypes.number, // specifica se la paginazione deve essere interna allo slider e di quanto
  dotsTheme: PropTypes.oneOf(['light', 'dark']), // specifica il tema dei dot dello slider
  nativeLayout: PropTypes.string, // layout dello slider solo per i dispositivi native (vedere documentazione slider native)
  ctaNext: PropTypes.string, // testo del cta per lo slider
}

export const defaultProps = {
  onChangeItem: () => {},
  itemsToShow: 1,
  nativeLayout: 'default',
  dotsTop: false,
  dotsInside: null,
  dotsTheme: 'light',
  ctaNext: null,
}
