import React, { useState, useEffect } from 'react'
import { getItem } from '../libs/storage'
import { availableLocales, defaultLocale, currentLocale, setCurrentLocale } from '../libs/locales'

export const MediaContext = React.createContext()

export function MediaProvider (props) {
  // configurazione per gli autoplay audio generici (trailer hero, autoplay a scroll)
  const [mute, setMute] = useState(true)

  // dati di configurazione del media player da utilizzare
  const [mediaPlayerPlay, setMediaPlayerPlay] = useState(false)
  const [mediaPlayerSource, setMediaPlayerSource] = useState(null)
  const [mediaPlayerType, setMediaPlayerType] = useState(null)
  const [mediaPlayerOptions, setMediaPlayerOptions] = useState({ // NOTE: Usato come documentazione delle options accettate
    name: null
  })

  // Funzioni di gestione del media player
  const resetMediaPlayer = () => {
    setMediaPlayerPlay(false)
    setMediaPlayerSource(null)
    setMediaPlayerType(null)
    setMediaPlayerOptions({})
  }
  const setMediaPlayer = (source, type, options) => {
    setMediaPlayerSource(source)
    setMediaPlayerType(type)
    setMediaPlayerOptions(options)
  }
  const toggleMediaPlayer = (value = null) => {
    if (value === null) {
      setMediaPlayerPlay(!mediaPlayerPlay)
    } else {
      setMediaPlayerPlay(value)
    }
  }

  return (
    <MediaContext.Provider value={{
      setMute,
      resetMediaPlayer,
      setMediaPlayer,
      toggleMediaPlayer,
      setMediaPlayerPlay,
      mute: mute,
      mediaPlayer: { play: mediaPlayerPlay, source: mediaPlayerSource, type: mediaPlayerType, options: mediaPlayerOptions }
    }} >
      {props.children}
    </MediaContext.Provider>
  )
}
