import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, Pressable } from 'react-native'
import TitleComponent from './TitleComponent'
import ImageComponent from './ImageComponent'
import ButtonComponent from './ButtonComponent'
import LoadingComponent from './LoadingComponent'
import config from '../config'
import { t } from '../libs/locales'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function CardComponent (props) {

  // renderizzo versione temporanea se sono in modalità di caricamento
  if (props.loading) {
    return (
      <View style={[styles.container, props.style]}>
        <View style={styles.touchableContainer}>
          <View style={{ width: '100%' }} >
            <View style={[styles.image, { height: props.vertical ? 400 : 200, width: '100%', backgroundColor: config.colors.background }]}>
              <LoadingComponent full />
            </View>
          </View>
          <TitleComponent labelSmall={true} label={'...'} style={styles.title} />
          <ButtonComponent label={t('cta_discover', props.language)} arrow primary />
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.touchableContainer}>
        <Pressable style={{ width: '100%' }} onPress={() => props.onPress(props.card)}>
          <ImageComponent height={props.vertical ? 400 : 200} width={'100%'} style={styles.image} source={props.image} cover />
        </Pressable>
        <TitleComponent labelSmall={true} label={props.title} style={styles.title} />
        {!!props.onPress && <ButtonComponent label={t('cta_discover', props.language)} onPress={() => props.onPress(props.card)} arrow primary />}
      </View>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    overflow: 'hidden',
    paddingBottom: 5
  },
  touchableContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    borderRadius: 50, 
    marginBottom: 20
  },
  title: {
    marginBottom: 18
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

CardComponent.propTypes = {
  title: PropTypes.string,
  vertical: PropTypes.bool,
  onPress: PropTypes.func,
  vertical: PropTypes.bool,
  loading: PropTypes.bool,
  language: PropTypes.string
}

CardComponent.defaultProps = {
  onPress: () => {}
}