import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import TutorialView from '../views/TutorialView'

export const TutorialStackNavigator = createStackNavigator()

export function TutorialNavigator (props) {
  return (
    <TutorialStackNavigator.Navigator
      screenOptions={{ headerShown: false }}
    >
      <TutorialStackNavigator.Screen name="Tutorial" component={TutorialView} />
    </TutorialStackNavigator.Navigator>
  )
}