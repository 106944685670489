import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import TitleComponent from './TitleComponent'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function BoxComponent (props) {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.header}>
        <TitleComponent label={props.title} />
      </View>
      <View style={styles.body}>
        {props.children}
      </View>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    ...config.style.shadow,
    ...config.style.block,
    overflow: config.consts.isNativeIos ? 'visible' : 'hidden', // to allow shadow on native ios
    backgroundColor: config.colors.white,
    padding: config.consts.isScreenMobile ? 20 : 60,
  },
  header: {
    marginBottom: config.consts.isScreenMobile ? 20 : 36
  },
  body: {}
})

// Component props
//////////////////////////////////////////////////////////////////////////

BoxComponent.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object
}