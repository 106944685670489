import React, { useState } from 'react'
import { View, Image } from 'react-native'
import PropTypes from 'prop-types'
import SliderComponent from './SliderComponent'
import ButtonComponent from './ButtonComponent'
import PaddedComponent from './PaddedComponent'
import config from '../config'


// Component code
//////////////////////////////////////////////////////////////////////////

export default function SliderImagesComponent (props) {
  const [itemWidth, setItemWidth] = useState(0)
  const [itemHeight, setItemHeight] = useState(0)
  const [current, setCurrent] = useState(0)

  // Gestione calcolo dimensione slide
  const onLayout = (e) => { // CALCOLO DIMENSIONE SINGOLA SLIDE
    const {x, y, width, height} = e.nativeEvent.layout
    setItemWidth(width)
    setItemHeight(height)
  }

  const renderSliderItem = (item, index) => {
    return (
      <View key={index} style={styles.slide}>
        <Image source={{ uri: item }} resizeMode={props.originalSize ? 'contain' : 'cover'} style={{ width: itemWidth, height: itemHeight }} />
      </View>
    )
  }

  return (
    <View onLayout={onLayout} style={[styles.container, props.style]}>
      <SliderComponent items={props.images || [{ loading: true }]} renderItem={renderSliderItem} currentItem={current} onChangeItem={setCurrent} itemsToShow={1} {...props.sliderProps} />

      <PaddedComponent style={styles.actions} container={false} vertical>
        <ButtonComponent label="Precedente" arrowLeft disabled={current <= 0} onPress={() => setCurrent(current - 1)} />
        <ButtonComponent label="Successivo" arrow disabled={current >= props.images.length - 1} onPress={() => setCurrent(current + 1)} />
      </PaddedComponent>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = {
  container: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  slide: {
    width: '100%',
    height: '100%'
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: config.consts.isScreenMobile ? 20 : 80
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

SliderImagesComponent.propTypes = {
  style: PropTypes.object,
  images: PropTypes.array,
  sliderProps: PropTypes.object,
  originalSize: PropTypes.bool
}

SliderImagesComponent.defaultProps = {
  sliderProps: {},
  originalSize: false
}