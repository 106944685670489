import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function ContainerComponent (props) {
  const renderContent = () => {
    return (
      <View style={[styles.container, props.style]}>
        <View style={styles.content}>
          {props.children}
        </View>
      </View>
    )
  }

  return props.scrollable ? <ScrollView style={{ height: 0 }} scrollEventThrottle={200} onScroll={(e) => {if (config.consts.isNative) e.persist(); props.onScroll(e)}}>{renderContent()}</ScrollView> : renderContent()
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {},
  content: {
    width: '100%'
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

ContainerComponent.propTypes = {
  style: PropTypes.object,
  scrollable: PropTypes.bool,
  onScroll: PropTypes.func
}

ContainerComponent.defaultProps = {
  onScroll: () => {}
}