import { Platform, Dimensions } from 'react-native'
import env from '../env.json'

const dimWindow = Dimensions.get('window')

export default {

  /**
   * Specifica i gruppo utilizzato per l'applicazione.
   * I gruppi rappresentano le diverse versioni di dati e UI usate dall'applicazione.
   */
  group: env.group_uuid || 'wal',

  /**
   * Specifica il nome usato dall'applicazione.
   */
  name: env.name || 'WaL',

  /**
   * Forza l'esecuzione dell'app come fosse eseguita al primo avvio.
   * In caso di valore "true" tutti i dati in storage vengono resettati all'avvio
   * dell'applicazione.
   */
  runAsFirstTime: false,

  /**
   * Specifica se l'applicazione è in esecuzione su dispositivi nativi.
   */
  isNative: ['android', 'ios'].includes(Platform.OS),

  /**
   * Specifica se l'applicazione è in esecuzione su dispositivi nativi IOS.
   */
  isNativeIos: ['ios'].includes(Platform.OS),

  /**
   * Specifica se l'applicazione è in esecuzione su dispositivi nativi IOS.
   */
   isNativeAndroid: ['android'].includes(Platform.OS),

  /**
   * Specifica se l'applicazione è in esecuzione su dispositivi web.
   */
  isWeb: ['web'].includes(Platform.OS),

  /**
   * Specifica se l'applicazione è in esecuzione su uno schermo mobile o desktop.
   */
  isScreenMobile: dimWindow.width < 768,
  isScreenDesktop: dimWindow.width >= 768,

  /**
   * Specifica le dimensioni de dispositivo.
   */
  screenWidth: dimWindow.width,
  screenHeight: dimWindow.height,

  /**
   * Specifica la versione di tutorial utilizzata.
   * Modificando la versione il tutorial viene ricaricato in modo
   * da essere rivisualizzato agli utenti che hanno già utilizzato l'app.
   * (al versione deve essere aumentata rispetto al tutorial precedente)
   */
  tutorialVersion: 1,

  /**
   * Identifica il token utilizzato per la mappa MapBox.
   */
  mapboxAccessToken: 'pk.eyJ1Ijoid2FsMiIsImEiOiJjbGdzMDNqODQwZnNqM3JsOGMxNGZ3bzFhIn0.WvoqS7_wWthOK66xXKKXdA',

  /**
   * CONTROLLI CUSTOM PER LE VARIE VERSIONI DELL'APPLICATIVO
   */
  isWal: !env.group_uuid || env.group_uuid == 'wal',

}