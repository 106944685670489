import React from 'react'
import * as WebViewComponentProps from './WebViewComponentProps'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function WebViewComponent (props) {
  return (
    <iframe src={props.source} style={styles.iframe} />
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = {
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
}

// Component props
//////////////////////////////////////////////////////////////////////////

WebViewComponent.propTypes = WebViewComponentProps.propTypes

WebViewComponent.defaultProps = WebViewComponentProps.defaultProps