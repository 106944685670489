import PropTypes from 'prop-types'

export const propTypes = {
  source: PropTypes.string.isRequired,
  poster: PropTypes.string,
  style: PropTypes.any,
  autoplay: PropTypes.bool, // auto avvia il video
  hidden: PropTypes.bool, // tiene in video nascosto fino a quando non parte
  loop: PropTypes.bool, // mantiene il video in loop
  background: PropTypes.bool, // allinea lo stile in modo da usare il video come background sempre al 100%

  // props di gestone video
  play: PropTypes.bool, // gestisce lo stato del player
  mute: PropTypes.bool, // gestisce lo stato dell'audio
}

export const defaultProps = {
  
}
