import React, { useState, useEffect, useContext } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { paramsGenerator } from '../libs/navigation'
import useApi from '../hooks/useApiHook'
import { MediaContext } from '../contexts/MediaContext'
import ContainerComponent from '../components/ContainerComponent'
import SpacerComponent from '../components/SpacerComponent'
import CardComponent from '../components/CardComponent'
import HeroComponent from '../components/HeroComponent'
import BoxComponent from '../components/BoxComponent'
import BoxSliderComponent from '../components/BoxSliderComponent'
import FullSliderComponent from '../components/FullSliderComponent'
import PaddedComponent from '../components/PaddedComponent'
import FooterComponent from '../components/FooterComponent'
import config from '../config'

export default function DashboardView(props) {
  const [hero, setHero] = useState(null)
  const [areas, setAreas] = useState(null)
  const [characters, setCharacters] = useState(null)
  const [slides, setSlides] = useState(null)
  const [areasIndexRequestStatus, areasIndexRequestSend] = useApi('areasIndex')
  const [charactersIndexRequestStatus, charactersIndexRequestSend] = useApi('charactersIndex')
  const [routesIndexRequestStatus, routesIndexRequestSend] = useApi('routesIndex')
  const { mediaPlayer: { play: mediaPlay }, resetMediaPlayer } = useContext(MediaContext)
  const isFocused = useIsFocused()

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    loadData()
    resetMediaPlayer()
    return () => resetMediaPlayer()
  }, [])

  //////////////////////////////////////////////////////////////////////////

  const loadData = () => {
    if (routesIndexRequestStatus || areasIndexRequestStatus || charactersIndexRequestStatus) return

    Promise.all([
      routesIndexRequestSend({ per_page: 5, evidence: 1, group_uuid: config.consts.group }),
      routesIndexRequestSend({ per_page: 5, group_uuid: config.consts.group }),
      areasIndexRequestSend({ per_page: 8, group_uuid: config.consts.group }),
      charactersIndexRequestSend({ per_page: 8, group_uuid: config.consts.group }),
    ]).then((responses) => {
      const routesHero = responses[0].result ? responses[0].payload.data : []
      const routesSlides = responses[1].result ? responses[1].payload.data : []
      const areas = responses[2].result ? responses[2].payload.data : []
      const characters = responses[3].result ? responses[3].payload.data : []

      setHero(routesHero.map((data) => ({
        id: data.uuid,
        title: data.name,
        text: data.description,
        image: data.url_image_preview.desktop,
        video: data.url_video_trailer,
        ctaText: 'Inizia il percorso',
        ctaOnPress: () => onGoToRoute(data)
      })))

      setSlides(routesSlides.map((data) => ({
        id: data.uuid,
        title: data.name,
        description: data.description,
        image: data.url_image_preview.desktop,
        length: data.length_label,
        duration: data.duration_label,
        vehicle: data.vehicle,
        ctaText : 'Inizia il percorso',
        ctaOnPress : () =>  onGoToRoute(data)
      })))

      setAreas(areas.map((a) => { a.id = a.uuid; return a }))

      setCharacters(characters.map((a) => { a.id = a.uuid; return a }))
    })
  }

  const onGoToRoute = (data) => {
    props.navigation.push('Route', paramsGenerator({ route_permalink: data.permalink, native: { data, screen_title: data?.name } }))
  }

  const onGoToArea = (data) => {
    props.navigation.push('Area', paramsGenerator({ area_permalink: data.permalink, native: { data, screen_title: data?.name } }))
  }

  const onGoToCharacter = (data) => {
    props.navigation.push('Character', paramsGenerator({ character_permalink: data.permalink, native: { data, screen_title: data?.name } }))
  }

  //////////////////////////////////////////////////////////////////////////

  const renderCardItem = (item, itemType, itemVertical = false) => {
    let onPressFunc = null
    if (itemType === 'area') onPressFunc = item?.routes.length > 1 ? onGoToArea : () => onGoToRoute({ permalink: item.routes_permalinks[0] })
    if (itemType === 'character') onPressFunc = item?.routes.length > 1 ? onGoToCharacter : () => onGoToRoute({ permalink: item.routes_permalinks[0] })
    return <CardComponent loading={!item} title={item?.name} image={item?.url_image_preview?.mobile} onPress={() => onPressFunc(item)} vertical={itemVertical} />
  }

  return (
    <ContainerComponent scrollable>
      <HeroComponent slides={hero} play={isFocused && !mediaPlay} />
      <SpacerComponent size={5} />
      <PaddedComponent horizontal>
        <BoxComponent title="Aree">
          <BoxSliderComponent items={areas} renderItem={(item) => renderCardItem(item, 'area')} />
        </BoxComponent>
      </PaddedComponent>
      <SpacerComponent size={5} />
      <PaddedComponent horizontal>
        <FullSliderComponent slides={slides} />
      </PaddedComponent>
      <SpacerComponent size={5} />
      <PaddedComponent horizontal>
        <BoxComponent title="Personaggi">
          <BoxSliderComponent items={characters} renderItem={(item) => renderCardItem(item, 'character', true)} />
        </BoxComponent>
      </PaddedComponent>
      <SpacerComponent size={5} />
      <FooterComponent />
    </ContainerComponent>
  )
}