import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import PropTypes from 'prop-types'
import ButtonCircleComponent from './ButtonCircleComponent'
import TextComponent from './TextComponent'
import config from '../config'
import { t } from '../libs/locales'

export default function RouteDetailsComponent(props) {
  const textColor = props.theme == 'dark' ? config.colors.black : config.colors.white
  const iconColor = props.theme == 'dark' ? config.colors.black : config.colors.white
  const buttonCircleSize = config.consts.isScreenMobile ? 45 : 50

  return(
    <View style={[styles.container, props.style]}>
      <View style={styles.detail}>
        <ButtonCircleComponent size={buttonCircleSize} color={iconColor} icon={'time'}/>
        <TextComponent style={styles.icon} label={props.duration} color={textColor} labelBold />
      </View>
      <View style={styles.detail}>
        <ButtonCircleComponent size={buttonCircleSize} color={iconColor} icon={'distance'}/>
        <TextComponent style={styles.icon} label={props.length} color={textColor} labelBold />
      </View>
      <View style={styles.detail}>
        {props.vehicle === 1 && (
          <> 
            <ButtonCircleComponent size={buttonCircleSize} color={iconColor} icon={'drive'}/>
            <TextComponent style={styles.icon} label={t('label_with_car', props.language)} color={textColor} labelBold />
          </>
        )}
        {props.vehicle === 2 && (
          <> 
            <ButtonCircleComponent size={buttonCircleSize} color={iconColor} icon={'bicycle'}/>
            <TextComponent style={styles.icon} label={t('label_with_bike', props.language)} color={textColor} labelBold />
          </>
        )}
        {props.vehicle === 3 && (
          <> 
            <ButtonCircleComponent size={buttonCircleSize} color={iconColor} icon={'walk'}/>
            <TextComponent style={styles.icon} label={t('label_with_walk', props.language)} color={textColor} labelBold />
          </>
        )}
      </View>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////
const styles = StyleSheet.create({
  container : {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  detail : {
    flexDirection: config.consts.isScreenMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: config.consts.isScreenMobile ? 'center' : 'flex-start',
    marginRight: 30
  },
  icon: {
    marginLeft: config.consts.isScreenMobile ? 0 : 10,
  }
})


// Component props
//////////////////////////////////////////////////////////////////////////

RouteDetailsComponent.propTypes = {
  detail: PropTypes.string,
  length: PropTypes.string,
  distance: PropTypes.string,
  duration: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  style: PropTypes.any,
  language: PropTypes.string
}

RouteDetailsComponent.defaultTypes = {
  theme: 'light'
}