import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import ButtonCircleComponent from './ButtonCircleComponent'
import TextComponent from './TextComponent'
import PaddedComponent from './PaddedComponent'
import ButtonComponent from './ButtonComponent'
import { getItem } from '../libs/storage'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MapNavigatorHeaderComponent (props) {
  const [debug, setDebug] = useState(false)

  useEffect(() => {
    getItem('debug').then((debug) => {
      setDebug(!!debug)
    })
  }, [])

  let renderVehicle = null
  if (props.vehicle == 1) {
    renderVehicle = () => <ButtonCircleComponent size={40} color={config.colors.black} icon={'drive'} onPress={() => props.onPressVehicle(3)} />
  } else if (props.vehicle == 2) {
    renderVehicle = () => <ButtonCircleComponent size={40} color={config.colors.black} icon={'bicycle'} onPress={() => props.onPressVehicle(1)} />
  } else {
    renderVehicle = () => <ButtonCircleComponent size={40} color={config.colors.black} icon={'walk'} onPress={() => props.onPressVehicle(2)} />
  }

  return (
    <PaddedComponent style={styles.actions} horizontal vertical >
      <View style={styles.actionsLeft}>
        {renderVehicle()}
        <View style={styles.actionsUser}>
          {props.distance && props.userPosition && <TextComponent label={`${props.distance.toFixed(2)} Km mancanti`} color={config.colors.primary} labelBold labelSmall />}
          {debug && (
            <TouchableOpacity onPress={() => props.onChangeNear(props.near > 0.09 ? 0.01 : props.near + 0.01)}>
              <TextComponent label={`${props.near.toFixed(2)} Km setup`} color={config.colors.black} labelBold labelSmall />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ButtonComponent label="Chiudi" onPress={props.onPressClose} secondary />
    </PaddedComponent>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  actions: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionsLeft:{
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionsUser: {
    marginLeft: 15
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MapNavigatorHeaderComponent.propTypes = {
  vehicle: PropTypes.number,
  distance: PropTypes.number,
  near: PropTypes.number,
  userPosition: PropTypes.any,
  onChangeNear: PropTypes.func,
  onPressClose: PropTypes.func,
  onPressVehicle: PropTypes.func,
}

MapNavigatorHeaderComponent.defaultProps = {
  vehicle: 99,
  onChangeNear: () => {},
  onPressClose: () => {},
  onPressVehicle: () => {}
}