import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigation } from '../libs/navigation'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { MediaContext } from '../contexts/MediaContext'
import config from '../config'
import PaddedComponent from '../components/PaddedComponent'
import ImageComponent from './ImageComponent'
import TextComponent from './TextComponent'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function FooterComponent (props) {
  if (config.consts.isWeb) return null

  const { mediaPlayer: { play: mediaPlay } } = useContext(MediaContext)
  if (config.consts.isNative) return null

  return (
    <View style={[styles.container, mediaPlay ? { paddingBottom: 175 } : null]}>
      <PaddedComponent style={styles.content} horizontal>
        <View style={[styles.block, styles.blockLeft]}>
          <ImageComponent height={45} source={'logo'} />
        </View>
        <View style={[styles.block]}>
          <TextComponent style={{ width: '100%' }} label={'© 2021 • Un progetto realizzato da Zeranta S.r.l.'} labelSmall labelCenter />
        </View>
          <View style={[styles.block, styles.blockRight]}>
            <ImageComponent height={50} style={styles.partner} source={'credits'} />
          </View>
      </PaddedComponent>
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////
const styles = StyleSheet.create({
  container: {
    ...config.style.shadow,
    ...config.style.FooterComponent.container
  },
  content: {
    width: '100%',
    flexDirection: config.consts.isScreenMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 36
  },
  block: {
    paddingHorizontal: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: config.consts.isScreenMobile ? '100%' : '33%',
    marginBottom: config.consts.isScreenMobile ? 50 : 0
  },
  blockLeft: {
    paddingHorizontal: 0,
    justifyContent: config.consts.isScreenMobile ? 'center' : 'flex-start'
  },
  blockRight: {
    paddingHorizontal: 0,
    justifyContent: config.consts.isScreenMobile ? 'center' : 'flex-end'
  },
  partner: {
    marginLeft: 15
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: config.colors.primary
  },
  menuItem: {
    marginRight: 5,
    paddingLeft: 5,
    borderLeftColor: config.colors.gray,
    borderLeftWidth: 1
  }
})
// Component props
//////////////////////////////////////////////////////////////////////////

FooterComponent.propTypes = {
  horizontal: PropTypes.bool,
  size: PropTypes.number
}

FooterComponent.defaultProps = {
  horizontal: false,
  size: 1
}