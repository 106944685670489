import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import config from '../config'

// Component code
//////////////////////////////////////////////////////////////////////////

export default function MapContainerComponent (props) {
  return (
    <View style={[styles.container, props.style]}>
      {props.loading ? null : props.children}
    </View>
  )
}

// Component style
//////////////////////////////////////////////////////////////////////////

const styles = StyleSheet.create({
  container: {
    ...config.style.shadow,
    ...config.style.block,
    overflow: config.consts.isNativeIos ? 'visible' : 'hidden', // to allow shadow on native ios
    width: '100%',
    height: config.consts.isScreenMobile ? 200 : 400,
    borderWidth: 6,
    borderColor: config.colors.white,
    backgroundColor: config.colors.gray
  }
})

// Component props
//////////////////////////////////////////////////////////////////////////

MapContainerComponent.propTypes = {
  style: PropTypes.any,
  loading: PropTypes.bool
}

MapContainerComponent.defaultProps = {}