import React, { useContext, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { navigation } from '../libs/navigation'
import { TutorialContext } from '../contexts/TutorialContext'
import ContainerComponent from '../components/ContainerComponent'
import PaddedComponent from '../components/PaddedComponent'
import TitleComponent from '../components/TitleComponent'
import TextComponent from '../components/TextComponent'
import ButtonComponent from '../components/ButtonComponent'
import ImageComponent from '../components/ImageComponent'
import SliderComponent from '../components/SliderComponent'
import config from '../config'

export default function TutorialView(props) {
  const [index, setIndex] = useState(0)
  const { manageTutorialViewed } = useContext(TutorialContext)

  const items = useMemo(() => {
    return [
      {
        title: 'Il museo diffuso',
        description: 'Scegli un personaggio storico,  vivi  una delle  grandi storie del nostro paese nella piccola provincia.'
      },
      {
        title: 'Incontri impossibili',
        description: 'Con Walk&Learn potrai fare incontri impossibili con i personaggi guida che hanno creato la storia dei nostri territori e della nostra nazione.'
      },
      {
        title: 'Realtà Immersiva',
        description: 'Potrai visitare luoghi unici con i nostri tour virtuali, veder arrivare la tua guida grazie a un video 360° o ascoltare la storia dei nostri personaggi.'
      }
    ]
  }, [])

  //////////////////////////////////////////////////////////////////////////

  const onTutorialCompleted = () => {
    manageTutorialViewed()
    navigation.current.reset({ index: 0, routes: [{ name: 'MainNavigator' }] })
  }

  //////////////////////////////////////////////////////////////////////////

  const renderItem = (item, itemIndex, itemActive) => {
    return (
      <PaddedComponent style={styles.slide} vertical horizontal>
        <View style={styles.slideContent}>
          <ImageComponent style={styles.logo} source="logo" width={200} />
          <ImageComponent style={styles.image} source={`tut${itemIndex + 1}`} width={180} />
          <TitleComponent style={styles.title} label={item.title} labelCenter />
          <TextComponent style={styles.description} label={item.description} labelCenter />
          {itemIndex == items.length - 1 ? (
            <ButtonComponent style={styles.cta} label="Continua" onPress={onTutorialCompleted} />
          ) : (
            <ButtonComponent style={styles.cta} label="Avanti" onPress={() => setIndex(itemIndex + 1)} />
          )}
        </View>
      </PaddedComponent>
    )
  }

  return (
    <ContainerComponent containerCentered >
      <SliderComponent style={{ height: '100%' }} items={items} renderItem={renderItem} onChangeItem={(i) => setIndex(i)} currentItem={index} dotsTheme='dark' dotsInside={60} dots />
    </ContainerComponent>
  )
}

const styles = StyleSheet.create({
  slide: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: 100
  },
  slideContent: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '75%',
    maxWidth: 300
  },
  logo: {
    marginBottom: 30
  },
  image: {
    marginBottom: 20
  },
  title: {
    marginBottom: 20
  },
  description: {
    marginBottom: 30
  },
  cta: {
    width: '100%'
  }
})