import en from '../locales/en'
import it from '../locales/it'
import gr from '../locales/gr'
const translations = { en, it, gr }

export let availableLocales = ['it']

export let defaultLocale = availableLocales[0]

export let currentLocale = null

export const setCurrentLocale = (value) => currentLocale = value

export const t = (key, locale = null) => ((translations[locale || currentLocale] && translations[locale || currentLocale][key]) ? translations[locale || currentLocale][key] : (translations[defaultLocale] && translations[defaultLocale][key] ? translations[defaultLocale][key] : key)).trim().replace(/^\w/, (c) => c.toUpperCase())
