import React, { useState, useEffect } from 'react'
import { FlatList, View } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import useApi from '../hooks/useApiHook'
import { paramsGenerator, titleGenerator } from '../libs/navigation'
import ContainerComponent from '../components/ContainerComponent'
import PaddedComponent from '../components/PaddedComponent'
import SpacerComponent from '../components/SpacerComponent'
import CardComponent from '../components/CardComponent'
import LoadingComponent from '../components/LoadingComponent'
import FooterComponent from '../components/FooterComponent'
import BoxComponent from '../components/BoxComponent'
import BoxGridComponent from '../components/BoxGridComponent'
import ButtonComponent from '../components/ButtonComponent'
import config from '../config'

export default function RoutesView(props) {
  const [routes, setRoutes] = useState([])
  const [page, setPage] = useState(1)
  const [showPage, setShowPage] = useState(true)
  const [routesIndexRequestStatus, routesIndexRequestSend] = useApi('routesIndex')
  const isFocused = useIsFocused()

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    props.navigation.setOptions({ title: titleGenerator('Percorsi') })
  }, [])

  useEffect(() => {
    loadData()
  }, [page])

  //////////////////////////////////////////////////////////////////////////

  const loadData = async () => {
    routesIndexRequestSend({ per_page: 9, page, group_uuid: config.consts.group }).then((response) => {
      const newRoutes = response.result ? response.payload.data : []
      setRoutes(routes.concat(newRoutes))

      if (newRoutes.length < 9) setShowPage(false)
    })
  }

  const onGoToRoute = (data) => {
    props.navigation.push('Route', paramsGenerator({ route_permalink: data.permalink, native: { data, screen_title: data.name } }))
  }

  //////////////////////////////////////////////////////////////////////////

  const renderCardItem = (item) => {
    return <CardComponent loading={!item} title={item?.name} image={item?.url_image_preview?.mobile} onPress={() => onGoToRoute(item)} />
  }

  if (config.consts.isWeb) {
    return (
      <ContainerComponent scrollable >
        {/* <HeroComponent slides={[{
          id: '123',
          title: 'Lorem ipsum',
          text: 'Lorem ipsum dolor sit amet',
          image: ''
        }]} /> */}
        <SpacerComponent size={5} />
        <PaddedComponent horizontal>
          <BoxComponent title={'Percorsi'}>
            <BoxGridComponent items={routes.length ? routes : [{ loading: true }, { loading: true }, { loading: true }]} renderItem={(item) => renderCardItem(item)} />

            {showPage && (
              <>
                <SpacerComponent size={5} />
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <ButtonComponent style={{ width: 200 }} label={'Carica altri'} loading={routesIndexRequestStatus} onPress={() => setPage(page + 1)} secondary />
                </View>
              </>
            )}
          </BoxComponent>
        </PaddedComponent>
        <SpacerComponent size={5} />

        <FooterComponent />
      </ContainerComponent>
    )
  }

  return (
    <ContainerComponent>
      <PaddedComponent>
        <FlatList
          data={routes}
          renderItem={({ item }) => renderCardItem(item)}
          onEndReached={() => setPage(page + 1)}
          style={{ paddingHorizontal: 30 }}
          ItemSeparatorComponent={() => <SpacerComponent size={5} />}
          ListHeaderComponent={() => <SpacerComponent size={3} />}
          ListFooterComponent={() => <SpacerComponent size={5} />}
          ListEmptyComponent={() => <LoadingComponent full />}
        />
      </PaddedComponent>
      <FooterComponent />
    </ContainerComponent>
  )
}